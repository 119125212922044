<template>
  <div
    class="block-auth-login"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-auth-login__inner">
      <AtomTextHeading
        v-if="title"
        :text="title"
        font-size="h3"
        class="block-auth-login__title"
      />

      <AtomTextRichtext
        v-if="text"
        :html="text"
        class="block-auth-login__text"
      />

      <div class="block-auth-login__form">
        <UtilRendererForm
          v-if="fields?.length > 0"
          :fields="fields"
          :submit-text="submitText"
          :button-alignment="buttonAlignment"
          :use-sentry-error="false"
          :success-message="successMessage"
          :additional-link="link"
          :additional-link-text="linkText"
          :additional-link-tooltip="linkTooltip"
          :error-message="errorMessageToShow"
          :callback="submitCallback"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    /*
        Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    notConfirmedErrorMessage: {
        type: String,
        default: '',
    },

    wrongCredentialsErrorMessage: {
        type: String,
        default: '',
    },

    blockedErrorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },

    /*
      Content
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    /*
      Additional Link
    */
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },

    linkTooltip: {
        type: String,
        default: '',
    },
});

const errorMessageToShow = ref('');
const submitCallback = async (data) => {
    const {
        error,
        data: response,
    } = await useLogin(data);

    if (response.notConfirmed || response.wrongCredentials || response.blocked) {
        if (response.notConfirmed) {
            errorMessageToShow.value = props.notConfirmedErrorMessage;
        }

        if (response.wrongCredentials) {
            errorMessageToShow.value = props.wrongCredentialsErrorMessage;
        }

        if (response.blocked) {
            errorMessageToShow.value = props.blockedErrorMessage;
        }

        throw createError('not confirmed || wrong credentials || blocked');
    }

    if (error) {
        errorMessageToShow.value = props.errorMessage;
    }
};
</script>

<style lang="scss" scoped>
.block-auth-login {
    @include wrapper-center;

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
    }
}

.block-auth-login__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);
    @include default-content-columns('narrow');
    position: relative;

    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;

    ::v-deep(p) {
        font-size: var(--f-size--h5);
    }
}

.block-auth-login__text,
.block-auth-login__title {
    width: 100%;
    max-width: 641px;
}

</style>

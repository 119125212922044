<template>
  <div class="atom-button-more">
    <transition name="fade-in">
      <button
        v-if="showLoadMore"
        type="button"
        class="atom-button-more__button"
        @click="loadMore"
      >
        <IonIcon
          icon-name="plus"
          :icon-color="iconColor"
        />
      </button>
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
      Styles
    */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-button-bg--primary)',
    },

    iconColor: {
        type: String,
        default: 'var(--c-button-text--primary)',
    },

    /*
      Data
    */
    totalItemAmount: {
        type: Number,
        default: 0,
        validator: (value) => value > 0,
    },

    currentItemAmount: {
        type: Number,
        default: 0,
        validator: (value) => value > 0,
    },
});

const {
    backgroundColor,
    borderRadius,
} = toRefs(props);

const showLoadMore = computed(() => props.currentItemAmount < props.totalItemAmount);
const emit = defineEmits(['on-load-more']);

const loadMore = () => {
    emit('on-load-more');
    useStorybookAction('on-load-more');
};
</script>

<style lang="scss" scoped>

.atom-button-more__button {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: v-bind(borderRadius);
    aspect-ratio: 1 / 1;
    background-color: var(--background-color);
    transition: transform 0.6s var(--ease-in--back);

    ::v-deep(.ion-icon__svg) {
        @include fluid('width', 13px, 11px, 15px);
    }
}

.atom-button-more {
    @include fluid('--button-height', 60px, 50px, 69px, false);

    --background-color: v-bind(backgroundColor);
    display: inline-flex;
    height: var(--button-height);
    cursor: pointer;
    transition: transform 0.3s var(--ease-out--back);

    @include hover {
        transform: scale(1.035);
    }
}
</style>

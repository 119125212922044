/*
    Play codes
*/
export const usePlayCodes = async (body) => {
    const { error: e, data } = await $fetch('/api/codes/play', {
        method: 'post',
        body,
    });

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }

    const wincodeCookie = useCookie('wincode');
    wincodeCookie.value = null;

    setCurrentWins(data);
    const localePath = useLocalePath();
    await navigateTo(localePath('/current-wins'));
};

/*
    Check codes
*/
export const useCheckCodes = async (body) => {
    const { error: e, data } = await $fetch('/api/codes/check', {
        method: 'post',
        body,
    });

    if (e) {
        useSentryError(e, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }

    return data;
};

/*
    Show free codes
*/
export const useShowFreeCodes = async () => {
    const { error: e, data } = await useFetch('/api/codes/free');

    if (e.value) {
        useSentryError(e.value, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }

    return data.value;
};

/*
    Get played codes
*/
export const useGetPlayedCodes = async (body) => {
    const { error: e, data } = await useFetch('/api/codes/played', {
        method: 'post',
        body,
    });

    if (e.value) {
        useSentryError(e.value, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }
    return data.value;
};

/*
    Redeem win
*/
export const useRedeemWin = async (body) => {
    const { error: e, data } = await useFetch('/api/codes/redeem', {
        method: 'post',
        body,
    });

    if (e.value) {
        useSentryError(e.value, {
            useThrow: true,
            payload: body,
            response: data,
        });
    }
    return data.value;
};

<template>
  <button
    type="button"
    class="atom-tab"
    :class="{
      'is-inactive': !isActive,
      'is-active': isActive,
    }"
  >
    {{ text }}
  </button>
</template>

<script setup>
const props = defineProps({
    /*
      State
    */
    isActive: {
        type: Boolean,
        default: false,
    },

    /*
      Content
    */
    text: {
        type: String,
        default: '',
    },

    /*
      Styles
    */
    activeBackgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    activeTextColor: {
        type: String,
        default: 'var(--c-white)',
    },

    inactiveBackgroundColor: {
        type: String,
        default: 'var(--c-grey--light)',
    },

    inactiveTextColor: {
        type: String,
        default: 'var(--c-primary)',
    },
});

const {
    activeBackgroundColor,
    activeTextColor,
    inactiveBackgroundColor,
    inactiveTextColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-tab {
    display: inline-flex;
    padding: 10px 15px;
    border-radius: 5px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--tab);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--tab);
    transition: background-color 0.15s ease-out, color 0.15s ease-out;
    white-space: nowrap;

    &.is-active {
        background: v-bind(activeBackgroundColor);
        color: v-bind(activeTextColor);
    }

    &.is-inactive {
        background: v-bind(inactiveBackgroundColor);
        color: v-bind(inactiveTextColor);
    }
}
</style>

<template>
  <div
    ref="popoverNotificationRef"
    class="atom-popover-notification"
    :class="[
      `is-type-${type}`,
    ]"
    @click="emit('on-hide')"
    @keypress="emit('on-hide')"
  >
    <div class="atom-popover-notification__inner">
      <div class="atom-popover-notification__message">
        <IonIcon
          v-if="icon"
          :icon-name="icon"
          :icon-color="type === 'success' ? 'var(--c-primary)' : 'var(--c-error)'"
          class="atom-popover-notification__icon"
          icon-size="22px"
        />
        <span>{{ text }}</span>
      </div>

      <button
        class="atom-popover-notification__close"
        type="button"
        @click="emit('on-hide')"
      >
        <IonIcon
          :icon-name="isMobile ? 'close' : 'close-small'"
          icon-color="var(--c-white)"
          :icon-size="isMobile ? '6px' : '10px'"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        default: '',
    },

    type: {
        type: String,
        default: 'success',
        validator: (value) => ['success', 'error'].includes(value),
    },

    useClickOutside: {
        type: Boolean,
        default: false,
    },
});

/*
      Variables
  */
const { type } = toRefs(props);
const emit = defineEmits(['on-hide']);
const icon = ref(null);

watchEffect(() => {
    switch (type.value) {
    case 'success':
        icon.value = 'checkmark-circle';
        break;
    case 'error':
        icon.value = 'crossmark-circle';
        break;
    default:
        icon.value = null;
        break;
    }
});

onMounted(() => {
    window.setTimeout(() => {
        emit('on-hide');
    }, 60000); // Close the notification after 60 seconds
});

const popoverNotificationRef = ref(null);
if (props.useClickOutside) {
    onMounted(() => {
        onClickOutside(popoverNotificationRef, () => {
            emit('on-hide');
        });
    });
}
</script>

<style lang="scss" scoped>
.atom-popover-notification {
    @include z-index('formNotification');

    position: absolute;
    top: 0px;
    right: 25px;
    left: 25px;
    width: 438px;
    max-width: calc(100% - 50px);
    border-radius: var(--b-radius--medium);
    margin: auto;
    backdrop-filter: blur(5px);
    background-color: var(--c-white--transparent);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: var(--box-shadow--regular);
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.atom-popover-notification__inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--description);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--description);
    pointer-events: none;

    .is-type-error & {
        color: var(--c-error);
    }

    .is-type-success & {
        color: var(--c-success);
    }

    .is-type-warning & {
        color: var(--c-warning);
    }
}

.atom-popover-notification__message {
    @include fluid('column-gap', 10px, 20px, 20px);

    display: flex;
    align-items: center;

    @include tablet {
        @include fluid('padding-left', 10px, 10px, 10px);
        @include fluid('padding-right', 10px, 10px, 10px);
    }
}

.atom-popover-notification__close {
    display: flex;
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background: var(--c-primary);
}
</style>

<template>
  <component
    :is="useComponent(item.component)"
    v-for="(item, lcpIndex) of data"
    v-bind="buildProps(item.component, item, lcpIndex)"
    :key="item._uid"
  />
</template>

<script setup>
defineProps({
    data: {
        type: Array,
        default: () => [],
    },
});

/*
  Here you can add additional mappings for your content
  components to match the cms agnostic components
*/
const { locale } = useI18n();

const buildProps = (component, data, lcpIndex) => {
    const originalProps = { ...data };

    /*
      AtomImage
    */
    if (component === 'AtomImage') {
        originalProps.width = 1500;
        originalProps.lazy = lcpIndex < 3 ? false : originalProps.lazy;
        originalProps.preload = lcpIndex < 3;
        originalProps.sizes = 'xs:320px sm:480px md:750px lg:750px xl:750px';
    }

    /*
      BlockNavigationCards
    */
    if (component === 'BlockNavigationCards') {
        const availableModules = getAvailableModules(
            {
                output: 'array',
                cardsOnly: true,
            },
        );

        if (availableModules?.length > 0) {
            originalProps.cards = availableModules?.map((card) => {
                const cardContent = card.content[locale.value];
                return {
                    title: cardContent?.cardTitle,
                    text: cardContent?.cardText,
                    image: cardContent?.cardImage,
                    link: cardContent?.cardLink,
                };
            });
        }
    }

    if (component === 'BlockSliderWins') {
        const prizes = [];
        const currentWins = useCookie('currentWins');

        const { content: cardsData } = getModuleData('cardPrize');

        currentWins.value?.forEach((win, index) => {
            let titleBase = '';
            const { prize } = win;

            switch (prize.key) {
            case 'instant':
                titleBase = cardsData.instantWinText || '';
                break;
            case 'points':
                titleBase = cardsData.pointsWinText || '';
                break;
            case 'digital':
                titleBase = cardsData.discountWinText || '';
                break;
            default:
                titleBase = cardsData.bummerWinText || '';
                break;
            }

            prizes.push({
                title: compileWithHandlebars(titleBase, prize),
                pretitle: `Code ${index + 1} - ${win.code}`,
                pointsBase: win.basic_points,
                image: prize.image?.src ? {
                    src: prize.image.src,
                    alt: prize.image.alt,
                } : null,
            });
        });

        originalProps.pointsText = cardsData.pointsText;
        originalProps.prizes = prizes;
    }

    /*
      Merge the module props with the original props and cleanup the object
    */
    let moduleContent = {};

    if (originalProps.moduleKey) {
        const moduleData = getModuleData(originalProps.moduleKey);
        moduleContent = moduleData.content;
        const { addons } = moduleData;

        if (addons) {
            moduleContent.addons = addons;
        }
    }

    originalProps.isOnGrid = true;
    originalProps._uid = getUid();

    const moduleContentCopy = { ...moduleContent };

    /* Remove system props for cleaner markup */
    const keyToClean = [
        'component',
        '_uid',
        'moduleKey',
        'cardTitle',
        'cardText',
        'cardImage',
        'cardLink',
        'settings',
    ];

    keyToClean.forEach((key) => {
        delete originalProps[key];
        delete moduleContentCopy[key];
    });

    return {
        ...originalProps,
        ...moduleContentCopy,
    };
};

/*
  Add more components here if you need them in the storybook render loop
*/
const availableComponents = {
    /* Atom */
    AtomTextHeading: resolveComponent('AtomTextHeading'),
    AtomTextRichtext: resolveComponent('AtomTextRichtext'),
    AtomImage: resolveComponent('AtomImage'),

    /* Auth */
    BlockAuthRegistration: resolveComponent('BlockAuthRegistration'),
    BlockAuthLogin: resolveComponent('BlockAuthLogin'),
    BlockAuthLoginMagic: resolveComponent('BlockAuthLoginMagic'),
    BlockAuthLoginMagicExpired: resolveComponent('BlockAuthLoginMagicExpired'),
    BlockAuthPasswordForgot: resolveComponent('BlockAuthPasswordForgot'),
    BlockAuthPasswordReset: resolveComponent('BlockAuthPasswordReset'),
    BlockAuthUserSettings: resolveComponent('BlockAuthUserSettings'),
    BlockAuthUserFreecodes: resolveComponent('BlockAuthUserFreecodes'),
    BlockAuthUserWins: resolveComponent('BlockAuthUserWins'),
    BlockAuthUserStampcard: resolveComponent('BlockAuthUserStampcard'),

    /* Navigation */
    BlockNavigationCards: resolveComponent('BlockNavigationCards'),
    BlockNavigationBack: resolveComponent('BlockNavigationBack'),

    /* Block */
    BlockPlayCodes: resolveComponent('BlockPlayCodes'),
    BlockSliderWins: resolveComponent('BlockSliderWins'),
    BlockAccordionText: resolveComponent('BlockAccordionText'),
    BlockListCodeorigins: resolveComponent('BlockListCodeorigins'),
    BlockRecommendEmail: resolveComponent('BlockRecommendEmail'),
};

const useComponent = (key) => availableComponents[key] || null;
</script>

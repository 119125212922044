/**
 * This function takes an input, its type, and a boolean indicating
 * whether to reverse the parsing process.
 * Depending on the type of the input, it performs different parsing operations:
 *
 * - For 'date' type, it parses the input as a date in 'DD / MM / YYYY' format,
 * converts it to the 'Europe/Berlin' timezone, and formats it as an ISO 8601 string.
 * If the parsing process is reversed, it formats the input as a date in 'DD / MM / YYYY' format.
 *
 * - For 'integer' type, it parses the input as an integer.
 * If the parsing process is reversed, it converts the input to a string.
 *
 * - For 'string' type, it converts the input to a string.
 * If the type is not recognized, it returns the input as is.
 * If the parsed date or integer is invalid, it returns null.
 *
 * @param {any} input - The input to parse.
 * @param {string} type - The type of the input ('date', 'integer', or 'string').
 * @param {boolean} reverse - Whether to reverse the parsing process.
 * @returns {string|number|null} - The parsed input, or null if the input is invalid.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

const { dayjs } = useDayJs();

export default (input, type, reverse = false) => {
    let date = null;
    let integer = null;

    switch (type) {
    case 'date':
        date = reverse
            ? dayjs(input, 'DD / MM / YYYY').tz('Europe/Berlin').format()
            : dayjs(input).format('DD / MM / YYYY');

        return date === 'Invalid Date' ? null : date;

    case 'integer':
        integer = parseInt(input, 10);
        return Number.isNaN(integer) ? null : integer;
    case 'string':
        return input.toString();
    default:
        return input;
    }
};

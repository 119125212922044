/* eslint-disable import/no-unresolved */
let isRegistered = false;

export default async () => {
    if (!isRegistered) {
        const { register } = await import('swiper/element/bundle');
        register();
        isRegistered = true;
    }
};

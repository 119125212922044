<template>
  <div
    v-if="link"
    class="block-navigation-back"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-navigation-back__inner">
      <UtilRouteLink
        :link="link"
        class="block-navigation-back__link"
      >
        <div class="block-navigation-back__button">
          <IonIcon
            icon-name="arrow-left"
            :icon-color="buttonIconColor"
          />
        </div>

        {{ text }}
      </UtilRouteLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },
    /*
        Styles
    */
    buttonBackgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    buttonIconColor: {
        type: String,
        default: 'var(--c-white)',
    },

    textColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    /*
        Cotent
    */
    link: {
        type: String,
        default: '/dashboard',
    },

    text: {
        type: String,
        default: '',
    },
});

const {
    buttonBackgroundColor,
    textColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-navigation-back {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height-a);

    &.is-on-grid {
        @include wrapper-layout();
        @include grid-layout();
        margin-bottom: var(--m-bottom--small);
    }

    ::v-deep(.ion-icon__svg) {
        @include fluid('width', 11px, 12px, 15px, false);
    }

    @include hover {
        .block-navigation-back__button {
            transform: scale(1.045);
        }
    }
}

.block-navigation-back__inner {
    .is-on-grid & {
        @include default-content-columns('wide');
    }
}
.block-navigation-back__link {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 22px;
}

.block-navigation-back__button {
    @include navigation-buttons;
    background-color: v-bind(buttonBackgroundColor);
}
</style>

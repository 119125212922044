<template>
  <div class="atom-input-code__group">
    <div class="atom-input atom-input__wrapper">
      <div
        class="atom-input-code"
        :class="{
          'has-input': hasInput,
          'has-error': showErrors || isInvalid,
        }"
      >
        <label class="atom-input-code__label" :for="name">
          {{ label + (isRequired ? ' *' : '') }}
        </label>

        <input
          :id="name"
          ref="inputRef"
          v-model="inputValue"
          :name="name"
          type="text"
          :placeholder="hasInput ? placeholder : null"
          class="atom-input-code__input"
          @focus="setFocus(true)"
          @focusout="setFocus(false)"
          @mouseenter="setHover(true)"
          @mouseleave="setHover(false)"
        >
      </div>

      <transition name="fade-in-slow">
        <AtomInputErrors
          v-if="showErrors"
          :errors="errors"
          :is-extended="showErrorList"
        />
      </transition>
    </div>

    <button
      type="button"
      class="atom-input-code__remove"
      :class="{
        'is-visible': hasRemove,
      }"
      @click="onRemove"
    >
      <IonIcon
        icon-name="minus-circle"
        icon-size="22px"
        class="atom-input-code__remove-icon"
        icon-color="var(--c-primary)"
      />
    </button>
  </div>
</template>

<script setup>

const props = defineProps({
    /*
          General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
    },

    hasRemove: {
        type: Boolean,
        default: true,
    },

    /*
          Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    isInvalid: {
        type: Boolean,
        default: false,
    },

    codeLength: {
        type: Number,
        default: 8,
    },

    /*
          Masking
    */
    mask: {
        type: String,
        default: null,
    },

    placeholder: {
        type: String,
        default: null,
    },

});

/*
      Intialze formfield
*/
const errors = ref([]);
const inputValue = ref(props.initialValue || '');

/*
      Handle input
*/
const emit = defineEmits([
    'set-input',
    'set-error',
    'on-reset',
    'on-remove',
    'on-code-length',
]);

/* On Remove */
const onRemove = () => {
    emit('on-remove');
};

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }

    const { validationErrors } = useFormfieldEmit(
        typeof value === 'string' ? value.toUpperCase() : '',
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    if (newValue.length > props.codeLength) return;

    handleData(newValue);
});

onMounted(() => {
    handleData(inputValue.value);
});

/*
      States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
    showErrorList,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
      Masking
*/
const inputRef = ref(null);

onMounted(() => {
    if (props.mask) {
        useMaska(inputRef.value, {
            mask: props.mask,
        });
    }
});

/*
      Reset value
*/
const resetValue = () => {
    isResetting.value = true;
    inputValue.value = '';
    emit('on-reset');
};

watch(() => inputValue.length, (newValue) => {
    if (newValue === props.codeLength) {
        emit('on-code-length');
    }
});

/*
    Expose
*/
defineExpose({
    resetValue,
    inputValue,
});
</script>

<style lang="scss" scoped>
.atom-input-code__group {
    display: flex;
    width: 100%;
}
.atom-input__wrapper {
    @include formFieldWrapper;
    flex-basis: 100%;
}

.atom-input-code__remove {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 0px;
    min-width: 42px;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    transition: min-width 0.3s ease-in-out, padding-left 0.3s ease-in-out, width 0.3s ease-in-out;

    &:not(.is-visible) {
        min-width: 0px;
        padding-left: 0;
        pointer-events: none;
        transition-delay: 0.1s;
    }

}

.atom-input-code__remove-icon {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    .is-visible & {
        opacity: 1;
        pointer-events: auto;
        transition-delay: 0.2s;
    }
}

.atom-input-code {
    @include fluid-simple(--horizontal-padding, 20px, 24px);
    @include formFieldBasicsText;

    &.has-icon-left {
        @include fluid-simple(--horizontal-padding, 56px, 74px);
    }

    &.is-hidden-input {
        display: none;
    }
}

.atom-input-code__label {
    @include formFieldLabelText;
}

.atom-input-code__input {
    @include formFieldInputText;

    text-transform: uppercase;
}
</style>

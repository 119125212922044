<template>
  <Teleport to="body">
    <div class="block-overlay-slot__wrapper" data-lenis-prevent>
      <Transition name="fade-in">
        <div v-if="isOverlayOpen || isOpen" class="block-overlay-slot">
          <button
            class="block-overlay-slot__close"
            type="button"
            @click="close"
          >
            <ClientOnly>
              <IonIcon
                icon-name="close"
                icon-color="var(--c-white)"
                icon-size="10px"
              />
            </ClientOnly>
          </button>

          <div class="block-overlay-slot__content-wrapper">
            <div class="block-overlay-slot__content">
              <slot />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script setup>
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const {
    isOpen,
} = toRefs(props);

watch(() => isOpen.value, (newValue) => {
    if (newValue) {
        stopLenis('global');
    } else {
        startLenis('global');
    }
});

/*
    Emit
*/
const emit = defineEmits(['on-close']);
const close = () => {
    emit('on-close');
    useStorybookAction('on-close');
};
</script>

<style lang="scss" scoped>
.block-overlay-slot {
    @include z-index('overlay');

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.95);
    scroll-behavior: smooth;
}

.block-overlay-slot__content-wrapper {
    @include fluid('row-gap', 45px, 85px, 85px);
    @include wrapper-center;
    @include wrapper-layout();

    align-items: inherit;
    padding-top: 0;
    padding-bottom: 0;
}

.block-overlay-slot__content {
    @include grid-layout();

    padding-top: 154px;
    padding-bottom: 154px;
    margin: auto 0;
    row-gap: 20px;

    ::v-deep(> *) {
        @include default-content-columns('medium');

    }

    ::v-deep(.atom-richtext) {
        @include grid-columns(1, var(--grid-columns));

        @include tablet {
            @include grid-columns(4, calc(var(--grid-columns) - 6));
        }

        @include desktop {
            @include grid-columns(4, calc(var(--grid-columns) - 9));
        }
    }
}

.block-overlay-slot__close {
    @include z-index('confirmOverlay');

    position: absolute;
    top: 30px;
    right: var(--page-padding);
    display: flex;
    width: 69px;
    height: 69px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background: var(--c-primary);

    @include fluid('top', 30px, 60px, 60px);

    @include tablet {
        right: 58px;
    }
}
</style>

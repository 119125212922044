<template>
  <div
    class="block-auth-login-magic-expired"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-auth-login-magic-expired__inner">
      <AtomTextHeading
        v-if="title"
        :text="title"
        font-size="h3"
        class="block-auth-login-magic-expired__title"
      />

      <AtomTextRichtext
        v-if="text"
        :html="text"

        class="block-auth-login-magi-expiredc__text"
      />

      <UtilRendererForm
        :submit-text="buttonText"
        :button-alignment="buttonAlignment"
        :additional-link="additionalLink"
        :additional-link-text="additionalLinkText"
        :error-message="errorMessage"
        :success-message="successMessage"
        :fields="formFields"
        class="block-auth-login-magic-expired__form"
        :additional-link-tooltip="additionalLinkTooltip"
        :callback="submitCallback"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    /*
          Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    /*
        Content
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    formFields: {
        type: Array,
        default: () => [],
    },

    errorMessage: {
        type: String,
        default: '',
    },

    successMessage: {
        type: String,
        default: '',
    },

    /*
        Links
    */
    additionalLink: {
        type: String,
        default: '',
    },

    additionalLinkText: {
        type: String,
        default: '',
    },

    additionalLinkTooltip: {
        type: String,
        default: '',
    },

    buttonLink: {
        type: String,
        default: '',
    },

    buttonText: {
        type: String,
        default: '',
    },
});

const submitCallback = async () => {
    const localePath = useLocalePath();
    navigateTo(localePath('login-magic'));
};
</script>

<style lang="scss" scoped>
.block-auth-login-magic-expired {
    @include wrapper-center;

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
    }
}

.block-auth-login-magic-expired__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);
    @include default-content-columns('narrow');
    position: relative;

    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;

    ::v-deep(p) {
        font-size: var(--f-size--h5);
    }
}

.block-auth-login-magic-expired__form {
    margin-top: -20px;
}
</style>

<template>
  <BlockOverlaySlot
    :is-open="isOpen"
    @on-close="onClose"
  >
    <AtomTextHeading
      v-if="title"
      :text="title"
      font-size="h3"
      html-tag="h3"
    />

    <AtomTextRichtext
      v-if="text"
      :html="text"
    />

    <div class="block-auth-registration__form">
      <UtilRendererForm
        v-if="formFields?.length > 0 && !pending"
        :fields="formFields"
        :submit-text="submitText"
        :success-message="successMessage"
        :error-message="errorMessage"
        :callback="submitCallback"
        :is-disabled="isDisabled"
        :use-reset="false"
      />
    </div>
  </BlockOverlaySlot>
</template>

<script setup>
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },

    /* Content */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    /* Form */
    formFields: {
        type: Array,
        default: () => [],
    },

    submitText: {
        type: String,
        default: '',
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    /*
      Code to redeem
    */
    code: {
        type: String,
        default: '',
    },
});

const {
    pending,
} = useLazyAsyncData(
    useUserMe,
    { server: false },
);

const { code } = toRefs(props);
const isDisabled = ref(false);
const emit = defineEmits(['on-close']);
const submitCallback = async (data) => {
    try {
        /* Update user data */
        await useUpdateUser(data);

        /* redeem code */
        await useRedeemWin({
            code: code.value,
        });

        isDisabled.value = true;
        await sleep(2500);
        emit('on-close', true);
    } catch (e) {
        isDisabled.value = false;
    }
};

const onClose = () => {
    emit('on-close', false);
};
</script>

<template>
  <div
    class="block-recommend-email"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div class="block-recommend-email__inner">
      <AtomTextHeading
        v-if="title"
        class="block-recommend-email__title"
        font-size="h3"
        html-tag="h1"
        :text="title"
      />

      <div class="block-recommend-email__text">
        <AtomTextRichtext
          v-if="text"
          class="block-recommend-email__text-richtext"
          :html="text"
        />
      </div>

      <AtomButton
        :text="buttonText"
        class="block-recommend-email__mailto"
        alignment="flex-start"
        @click="openMailClient()"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    formFields: {
        type: Array,
        default: () => [],
    },

    buttonText: {
        type: String,
        default: '',
    },

    emailMessage: {
        type: String,
        default: '',
    },

    emailSubject: {
        type: String,
        default: '',
    },
});

const openMailClient = async () => {
    const mailtoHref = `mailto:?subject=${encodeURIComponent(props.emailSubject)}&body=${encodeURIComponent(props.emailMessage)}`;
    const mailtoLink = document.createElement('a');
    mailtoLink.href = mailtoHref;
    mailtoLink.click();
    mailtoLink.remove();
};
</script>

<style lang="scss" scoped>
.block-recommend-email {

    &.is-on-grid {
        @include wrapper-layout();
        @include grid-layout();
    }
}

.block-recommend-email__inner {
    @include fluid('row-gap', 20px, 15px, 20px, false);

    display: flex;
    flex-direction: column;

    .is-on-grid & {
        @include default-content-columns('wide');
    }

    @include box-default;
}

.block-recommend-email__mailto {
    justify-content: flex-start;
}
</style>

export const isOverlayOpen = ref(false);
export const dataOverlay = ref({});

export const openOverlay = (data) => {
    isOverlayOpen.value = true;
    dataOverlay.value = data;
};

export const closeOverlay = () => {
    isOverlayOpen.value = false;
    dataOverlay.value = {};
};

export const toggleOverlay = () => {
    isOverlayOpen.value = !isOverlayOpen.value;
};

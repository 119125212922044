<template>
  <div class="block-card-prize">
    <div
      v-if="pointsBase && pointsBase > 0"
      class="block-card-prize__points"
    >
      <span class="block-card-prize__points-amount">+ {{ pointsBase }}</span>
      <span class="block-card-prize__points-text">{{ pointsText }}</span>
    </div>

    <div class="block-card-prize__content">
      <div class="block-card-prize__texts">
        <div v-if="pretitle" class="block-card-prize__pretitle">
          {{ pretitle }}
        </div>

        <div v-if="title" class="block-card-prize__title">
          {{ title }}
        </div>
      </div>

      <div v-if="image?.src" class="block-card-prize__image">
        <AtomImage
          :image="image"
          behaviour="cover"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
      Styles
    */
    backgroundColor: {
        type: String,
        default: 'var(--c-white)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    accentColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
      Content
    */
    title: {
        type: String,
        default: null,
    },

    pretitle: {
        type: String,
        default: null,
    },

    pointsBase: {
        type: Number,
        default: 0,
    },

    pointsText: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => ({}),
    },
});

const {
    backgroundColor,
    borderRadius,
    accentColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>

.block-card-prize {
    @include fluid('padding-left', 15px, 32px, 58px, false);
    @include fluid('padding-right', 15px, 32px, 58px, false);
    @include fluid('padding-top', 90px, 134px, 185px, false);
    @include fluid('padding-bottom', 15px, 30px, 40px, false);
    @include fluid('min-height', 256px, 325px, 547px, false);

    --accent-color: v-bind(accentColor);

    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    font-family: var(--f-family--primary);
    transition: transform 0.3s var(--ease-out--back);
}

.block-card-prize__points {
    @include fluid('right', 15px, 32px, 58px, false);
    @include fluid('top', 15px, 30px, 40px, false);
    @include fluid('height', 65px, 94px, 115px, false);
    @include fluid('width', 108px, 118px, 138px, false);

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: var(--box-shadow--regular-soft);

    @include tablet {
        border-radius: v-bind(borderRadius);
    }

}

.block-card-prize__points-amount {
    color: var(--accent-color);
    font-size: var(--f-size--value);
    font-weight: var(--f-weight--900);
    line-height: var(--l-height--value);

    @include tablet {
        font-size: var(--f-size--h2);
        font-weight: var(--f-weight--700);
        line-height: var(--l-height--h2);
    }
}

.block-card-prize__points-text {
    color: var(--accent-color);
    font-size: var(--f-size--description);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--description);
}

.block-card-prize__content {
    @include fluid('row-gap', 10px, 10px, 20px, false);

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.block-card-prize__texts {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.block-card-prize__title {
    color: var(--accent-color);
    font-size: var(--f-size--h4);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h4);

    @include tablet {
        font-size: var(--f-size--h3);
        font-weight: var(--f-weight--500);
        line-height: var(--l-height--h3);
    }
}

.block-card-prize__image {
    @include fluid('width', 55px, 56px, 176px);
    @include fluid('height', 55px, 56px, 176px);

    overflow: hidden;
    border: 2px solid var(--c-grey--light);
    border-radius: var(--b-radius--full);
}
</style>

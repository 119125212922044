<template>
  <div
    class="block-accordion-text-entry"
    :class="{ 'is-open': isOpen }"
  >
    <button
      class="block-accordion-text-entry__header"
      type="button"
      @click="toggleAccordion"
    >
      <AtomTextHeading
        html-tag="h4"
        font-size="h4"
        class="block-accordion-text-entry__title"
        :text="title"
        font-weight="var(--f-weight--400)"
      />

      <div class="block-accordion-text-entry__icon">
        <IonIcon
          icon-name="arrow-bottom"
          icon-color="var(--c-white)"
          icon-size="12px"
        />
      </div>
    </button>

    <div
      ref="accordionItemRef"
      class="block-accordion-text-entry__content"
    >
      <div class="block-accordion-text-entry__content-inner">
        <AtomTextRichtext
          v-if="text"
          :html="text"
        />

        <UtilRouteLink
          v-if="link.href"
          :link="link.href"
          class="block-accordion-text-entry__link"
        >
          {{ link.text }}
        </UtilRouteLink>

        <div class="block-accordion-text-entry__buttons">
          <UtilRouteLink
            v-for="(button, index) of buttons"
            :key="`accordion-button-${Math.random() + index}`"
            :link="button.href"
          >
            <AtomButton :text="button.text" />
          </UtilRouteLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    accentColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    link: {
        type: Object,
        default: () => ({}),
    },

    buttons: {
        type: Array,
        default: () => [],
    },
});

const isOpen = ref(false);
const currentItemHeight = ref('0px');
const accordionItemRef = ref(null);

const toggleAccordion = () => {
    isOpen.value = !isOpen.value;
    currentItemHeight.value = isOpen.value ? `${accordionItemRef.value.scrollHeight}px` : '0px';
};

const { width } = useWindowSize();
watch(() => width.value, () => {
    if (isOpen.value) {
        currentItemHeight.value = `${accordionItemRef.value.scrollHeight}px`;
    }
});
</script>

<style lang="scss" scoped>
.block-accordion-text-entry {
    @include fluid('padding-top', 25px, 20px, 25px, false);
    @include fluid('padding-bottom', 25px, 20px, 25px, false);
    --padding-right: 75px;

    @include desktop {
        --padding-right: 200px;
    }

    --accent-color: v-bind(accentColor);

    border-top: 1px solid var(--c-primary--light);

    &:last-child {
        border-bottom: 1px solid var(--c-primary--light);
    }
}

.block-accordion-text-entry__header {

    position: relative;
    width: 100%;
    padding-right: var(--padding-right);
}

.block-accordion-text-entry__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    margin: auto;
    background-color: var(--accent-color);
    transition: transform 0.4s var(--ease-out--back);

    .is-open & {
        transform: rotate(-180deg);
    }
}

.block-accordion-text-entry__content {
    overflow: hidden;
    max-height: v-bind(currentItemHeight);
    padding-right: var(--padding-right);
    transition: max-height 0.4s var(--ease-out--quint);

    & > * {
        &:first-child {
            @include fluid('margin-top', 25px, 20px, 25px, false);
        }
    }
}

.block-accordion-text-entry__content-inner {
    @include fluid('row-gap', 15px, 20px, 20px, false);

    display: flex;
    flex-direction: column;

    opacity: 0;
    transition: opacity 0.3s ease-out;

    .is-open & {
        opacity: 1;
        transition-delay: 0.15s;
    }
}

.block-accordion-text-entry__link {
    color: var(--accent-color);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a--small);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--a--small);
    text-decoration: underline;
}

.block-accordion-text-entry__buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}
</style>

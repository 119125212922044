<template>
  <div
    class="atom-input-link"
    :class="[`is-alignment-${alignment}`]"
  >
    <UtilRouteLink
      v-if="link"
      :link="link"
    >
      {{ linkText }}
    </UtilRouteLink>
  </div>
</template>

<script setup>
const props = defineProps({
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },

    linkColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },
});

const { linkColor } = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-input-link {
    display: flex;
    flex-wrap: wrap;
    color: v-bind(linkColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a--small);
    font-weight: var(--f-weight--400);
    line-height: var(--l-height--a--small);
    text-decoration: underline;

    &.is-alignment-left {
        justify-content: flex-start;
    }

    &.is-alignment-center {
        justify-content: center;
    }

    &.is-alignment-right {
        justify-content: flex-end;
    }
}
</style>

<template>
  <div
    class="block-auth-usersettings"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-auth-usersettings__inner">
      <AtomTextHeading
        v-if="title"
        :text="title"
        font-size="h3"
        class="block-auth-usersettings__title"
      />

      <AtomTextRichtext
        v-if="text"
        :html="text"
        class="block-auth-usersettings__text"
      />

      <div class="block-auth-usersettings__form">
        <Transition name="fade-in">
          <UtilRendererForm
            v-if="fields?.length > 0 && !pending"
            :fields="fields"
            :submit-text="submitText"
            :button-alignment="buttonAlignment"
            :success-message="successMessage"
            :additional-link="link"
            :additional-link-text="linkText"
            :additional-link-tooltip="linkTooltip"
            :error-message="errorMessage"
            :callback="submitCallback"
            :use-reset="false"
            :has-link-action="true"
            @on-link-action="onLinkAction"
          />
        </Transition>
      </div>
    </div>

    <BlockOverlayConfirmation
      ref="confirmationRef"
      :text="confirmationText"
      :title="confirmationTitle"
      :is-open="confirmationOpen"
      :confirm-text="confirmationConfirmText"
      :reject-text="confirmationRejectText"
    />
  </div>
</template>

<script setup>
defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    /*
        Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },

    /*
      Content
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    /*
      Additional Link
    */
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },

    linkTooltip: {
        type: String,
        default: '',
    },

    /*
      Confirmation
    */
    confirmationTitle: {
        type: String,
        default: '',
    },

    confirmationText: {
        type: String,
        default: '',
    },

    confirmationConfirmText: {
        type: String,
        default: 'Confirm',
    },

    confirmationRejectText: {
        type: String,
        default: 'Reject',
    },
});

/*
  Update User
*/
const submitCallback = async (data) => {
    await useUpdateUser(data);
};

const {
    pending,
} = useLazyAsyncData(
    useUserMe,
    { server: false },
);

/*
  Delete User
*/
const confirmationOpen = ref(false);
const confirmationRef = ref(null);

const onLinkAction = async () => {
    confirmationOpen.value = true;
    const confirmationPromise = confirmationRef.value.createPromise();
    const confirmed = await confirmationPromise;

    if (!confirmed) {
        confirmationOpen.value = false;
        return;
    }

    confirmationOpen.value = false;

    try {
        await useDeleteUser('/login');
    } catch (e) { /* handled by composable */ }
};
</script>

<style lang="scss" scoped>
.block-auth-usersettings {
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--big);
    }
}

.block-auth-usersettings__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);
    @include default-content-columns('wide');

    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;

    ::v-deep(p) {
        font-size: var(--f-size--h5);
    }

    ::v-deep(.has-addional-link) {
        width: 100%;
        justify-content: space-between;
    }
}

.block-auth-usersettings__text,
.block-auth-usersettings__title {
    width: 100%;
    max-width: 641px;

}

</style>

<template>
  <div class="atom-input atom-input__wrapper">
    <div
      class="atom-input-text"
      :class="{
        'has-input': hasInput,
        'is-disabled': isDisabled,
        'has-icon-left': iconPosition === 'left' && icon !== 'none' && inputType !== 'password',
        'has-error': showErrors,
        'is-hidden-input': isHidden,
      }"
    >
      <label class="atom-input-text__label" :for="name">
        {{ label + (isRequired ? ' *' : '') }}
      </label>

      <IonIcon
        v-if="icon !== 'none' && iconPosition === 'left' && inputType !== 'password'"
        :icon-name="inputType === 'password' ? 'reveal' : icon"
        class="atom-input-text__icon is-left"
        icon-size="25px"
      />

      <input
        :id="name"
        ref="inputRef"
        v-model="inputValue"
        :name="name"
        :disabled="isDisabled"
        :type="computedInputType"
        :placeholder="hasInput ? placeholder : null"
        :autocomplete="autocompleteName ? autocompleteName : null"
        class="atom-input-text__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      >

      <IonIcon
        v-if="(icon !== 'none' && iconPosition === 'right') || inputType === 'password'"
        class="atom-input-text__icon is-right"
        :class="{ 'has-pointer': inputType === 'password' }"
        :icon-name="inputType === 'password' ? 'reveal' : icon"
        icon-size="25px"
        @click="inputType === 'password' ? toggleVisibility() : null"
        @keypress="inputType === 'password' ? toggleVisibility() : null"
      />
    </div>

    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
      />
    </transition>
  </div>
</template>

<script setup>
/**
 * This component provides an input field with label, icon, and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {string} [props.icon='none'] - The icon to display in the input field.
 * @param {string} [props.iconPosition='right'] - The position of the icon in the input field.
 * @param {string} [props.inputType='text'] - The type of input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    autocompleteName: {
        type: String,
        default: null,
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    icon: {
        type: String,
        default: 'none',
        validator: (value) => [
            'none',
            'profile',
            'date',
            'password',
        ].includes(value),
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    inputType: {
        type: String,
        default: 'text',
        validator: (value) => [
            'text',
            'email',
            'password',
        ].includes(value),
    },

    isHidden: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    parsing: {
        type: String,
        default: 'string',
        validator: (value) => [
            'string',
            'date',
            'integer',
        ].includes(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Masking
    */
    mask: {
        type: String,
        default: null,
    },

    placeholder: {
        type: String,
        default: null,
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref(
    useInputParser(compileWithHandlebars(props.initialValue, user.value), props.parsing) || '',
);

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (props.isDisabled) return;

    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }

    const { validationErrors } = useFormfieldEmit(
        useInputParser(value, props.parsing, true),
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    if (props.isDisabled) return;
    handleData(newValue);
});

onMounted(() => {
    if (props.isDisabled) return;
    handleData(inputValue.value);
});

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
    showErrorList,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom values for this kind of input
*/
/* Input Type */
const passwordVisible = ref(false);
const toggleVisibility = () => {
    passwordVisible.value = !passwordVisible.value;
};

const computedInputType = computed(() => {
    /* If input is password, return text or password */
    if (props.inputType === 'password') {
        return passwordVisible.value ? 'text' : 'password';
    }

    /* Else, return input type */
    return props.inputType;
});

/*
    Masking
*/
const inputRef = ref(null);

onMounted(() => {
    if (props.mask) {
        useMaska(inputRef.value, {
            mask: props.mask,
        });
    }
});

/*
    Reset value
*/
const resetValue = () => {
    isResetting.value = true;
    inputValue.value = '';
    emit('on-reset');
};

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;
}

.atom-input-text {
    @include fluid-simple(--horizontal-padding, 20px, 24px);
    @include formFieldBasicsText;

    &.has-icon-left {
        @include fluid-simple(--horizontal-padding, 56px, 74px);
    }

    &.is-hidden-input {
        display: none;
    }
}

.atom-input-text__label {
    @include formFieldLabelText;
}

.atom-input-text__input {
    @include formFieldInputText;
}

.atom-input-text__icon {
    @include formFieldInputIcon;
}
</style>

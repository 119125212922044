<template>
  <div
    class="block-auth-user-stampcard"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div class="block-auth-user-stampcard__inner">
      <div class="block-auth-user-stampcard__header">
        <AtomTextHeading
          v-if="title"
          class="block-auth-user-stampcard__title"
          font-size="h3"
          html-tag="h2"
          :text="title"
        />

        <div class="block-auth-user-stampcard__buttons">
          <AtomButton
            v-for="(button, index) in buttons"
            :key="`stampcard-button-${index}`"
            class="block-auth-user-stampcard__header-button"
            :background-color="button.backgroundColor"
            :text-color="button.textColor"
            :border-color="button.borderColor"
            :text="button.label"
            :icon="button.icon"
            :icon-position="button.iconPosition"
            :icon-color="button.iconColor"
            :icon-size="button.iconSize"
            :border-radius="button.borderRadius"
            size="small"
            @click="handleButtonClick(button.action)"
          />
        </div>

        <div class="block-auth-user-stampcard__tabs">
          <AtomButton
            v-for="(button, index) in tabs"
            :key="`stampcard-tab-button-${index}`"
            class="block-auth-user-stampcard__header-tab"
            :text="button.label"
            :is-inactive-tab="activeTab !== button.name"
            @click="activeTab = button.name"
          />
        </div>
      </div>

      <div class="block-auth-user-stampcard__content">
        <div
          :class="{ 'is-visible': activeTab === 'stampcard' }"
          class="block-auth-user-stampcard__stampcard"
        >
          <div class="block-auth-user-stampcard__summary">
            <div
              class="block-auth-user-stampcard__card"
              :class="{ 'is-skeleton': pending }"
            >
              <span class="block-auth-user-stampcard__card-title">{{ points }}</span>
              <span class="block-auth-user-stampcard__card-description">{{ pointsText }}</span>
            </div>

            <div
              class="block-auth-user-stampcard__card"
              :class="{ 'is-skeleton': pending }"
            >
              <span class="block-auth-user-stampcard__card-title">{{ amountOfCodes }}</span>
              <span class="block-auth-user-stampcard__card-description">{{ scannedText }}</span>
            </div>
          </div>

          <div class="block-auth-user-stampcard__stickers">
            <AtomSticker
              v-for="(sticker, index) of stickersWithoutLast"
              :key="`stampcard-sticker-${index}`"
              :is-collected="sticker.collected"
              :is-skeleton="pending"
              :background-color="sticker.collected ? sticker.background_color : 'red'"
              :amount="sticker.amount"
              :unit="getStickerUnit(sticker.unit)"
              :unit-position="getStickerUnitPosition(sticker.unit)"
              :empty-image="stickerEmptyImage"
              :image="stickerImage"
            />

            <div
              v-if="lastSticker"
              class="block-auth-user-stampcard__last-sticker"
            >
              <div
                class="block-auth-user-stampcard__last-sticker-sticker"
              >
                <AtomSticker
                  :is-collected="lastSticker.collected"
                  :amount="lastSticker.amount"
                  :unit="getStickerUnit(lastSticker.unit)"
                  :is-skeleton="pending"
                  :unit-position="getStickerUnitPosition(lastSticker.unit)"
                  :background-color="lastSticker.background_color"
                  :empty-image="stickerEmptyImage"
                  :image="stickerImage"
                  :is-last="true"
                />
              </div>
              <div class="block-auth-user-stampcard__last-sticker-description">
                <div class="block-auth-user-stampcard__last-sticker-text">
                  {{ lastStickerText }}
                </div>

                <UtilRouteLink v-if="lastStickerButton?.link" :link="lastStickerButton.link">
                  <AtomButton
                    :text="lastStickerButton.label"
                    size="small"
                    icon-position="left"
                    border-radius="var(--b-radius--full)"
                    :icon="lastStickerButton.icon"
                    :icon-size="lastStickerButton.iconSize"
                  />
                </UtilRouteLink>
              </div>
            </div>
          </div>
        </div>

        <BlockAuthUserLeaderboard
          v-if="isMounted"
          class="block-auth-user-stampcard__leaderboard"
          :class="{ 'is-visible': activeTab === 'leaderboard' }"
          v-bind="leaderboardContent"
        />
      </div>
    </div>

    <BlockOverlaySlot
      :is-open="isModalOpen"
      @on-close="isModalOpen = false"
    >
      <AtomTextHeading
        v-if="overlayTitle"
        :text="overlayTitle"
        font-size="h3"
      />
      <AtomImage
        v-if="overlayImage"
        :image="overlayImage"
        border-radius="var(--b-radius--big)"
      />
      <AtomTextRichtext
        v-if="overlayText"
        :html="overlayText"
      />
    </BlockOverlaySlot>
  </div>
</template>

<script setup>
const props = defineProps({
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    /* Content */
    title: {
        type: String,
        default: '',
    },

    buttons: {
        type: Array,
        default: () => [],
    },

    tabs: {
        type: Array,
        default: () => [],
    },

    pointsText: {
        type: String,
        default: '',
    },

    scannedText: {
        type: String,
        default: '',
    },

    totalPoints: {
        type: Number,
        default: 0,
    },

    codesPlayed: {
        type: Number,
        default: 0,
    },

    /* Stickers */
    stickers: {
        type: Array,
        default: () => [],
    },

    stickerImage: {
        type: Object,
        default: () => ({}),
    },

    stickerEmptyImage: {
        type: Object,
        default: () => ({}),
    },

    lastStickerText: {
        type: String,
        default: '',
    },

    lastStickerButton: {
        type: Object,
        default: () => {},
    },

    /* Overlay Content */
    overlayTitle: {
        type: String,
        default: '',
    },

    overlayImage: {
        type: Object,
        default: () => ({}),
    },

    overlayText: {
        type: String,
        default: '',
    },

    addons: {
        type: Array,
        default: () => ([]),
    },
});

const {
    codesPlayed,
    totalPoints,
} = toRefs(props);

const isMounted = ref(false);
const stickersData = ref(Array.from({ length: 10 }, () => ({})));

onMounted(() => {
    isMounted.value = true;
});

const lastSticker = computed(() => {
    if (!stickersData.value?.length) return {};
    return stickersData.value[stickersData.value.length - 1];
});
const stickersWithoutLast = computed(() => {
    if (!stickersData.value?.length) return [];
    return stickersData.value.slice(0, stickersData.value.length - 1);
});

const points = ref(totalPoints.value);
const amountOfCodes = ref(codesPlayed.value);

/*
    Fetch stickers
*/
const {
    pending,
    data,
    error,
} = useLazyFetch(
    '/api/codes/stampcard',
    { server: false },
);

watch(() => error.value, (e) => {
    if (e) {
        useSentryError(e, {
            useThrow: false,
            response: data.value,
        });
    }
});

watchEffect(() => {
    if (data?.value && Array.isArray(data.value.stickers)) {
        points.value = data.value.points_total;
        amountOfCodes.value = data.value.codes_played;
        stickersData.value = data.value.stickers;
    }
});

/*
    header Buttons
*/
const localePath = useLocalePath();
const isModalOpen = ref(false);

const handleButtonClick = async (action) => {
    switch (action) {
    case 'navigateToPlay':
        await navigateTo(
            localePath('/play-codes'),
        );
        break;
    case 'openModal':
        isModalOpen.value = true;
        break;
    default:
        break;
    }
};

/*
  active tab
*/
const activeTab = ref(props.tabs?.length ? props.tabs[0].name : 'stampcard');

/*
    Addons
*/
const leaderboardContent = computed(() => {
    const addon = props.addons?.find((searchAddon) => searchAddon.name === 'leaderboard');
    return addon?.content || null;
});

/*
  Sticker Unit
*/
const getStickerUnit = (unit) => {
    if (unit === 'multiply') {
        return 'x';
    }
    if (unit === 'add') {
        return '+';
    }
    return '';
};

const getStickerUnitPosition = (unit) => {
    if (unit === 'add') {
        return 'left';
    }
    return 'right';
};
</script>

<style lang="scss" scoped>
.block-auth-user-stampcard {
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout;

        margin-bottom: var(--m-bottom--big);
    }
}

.block-auth-user-stampcard__inner {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .is-on-grid & {
        @include default-content-columns('wide');
        @include box-default;
    }
}

.block-auth-user-stampcard__header {
    display: flex;

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;
}

.block-auth-user-stampcard__title {
    display: flex;
    min-height: 48px;
    align-items: center;
}

.block-auth-user-stampcard__buttons {
    @include fluid('column-gap', 5px, 5px, 10px);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 10px;
}

.block-auth-user-stampcard__summary {
    @include fluid('margin-bottom', 20px, 20px, 90px);

    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
}

.block-auth-user-stampcard__card {
    position: relative;
    display: flex;
    min-width: 178px;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: var(--b-radius--big);
    background: var(--c-white);
    box-shadow: var(--box-shadow--big);
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    text-align: center;

    &.is-skeleton {
        @include skeleton-loading(var(--b-radius--big));
    }
}

.block-auth-user-stampcard__card-title {
    min-width: 40px;
    font-size: var(--f-size--h2);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--h2);
}

.block-auth-user-stampcard__card-description {
    font-size: var(--f-size--description);
    line-height: var(--l-height--description);
}

.block-auth-user-stampcard__card-title,
.block-auth-user-stampcard__card-description {
    position: relative;
}

.block-auth-user-stampcard__stickers {
    @include fluid('row-gap', 10px, 20px, 30px);
    @include fluid('column-gap', 10px, 20px, 30px);

    display: grid;
    width: 100%;
    column-gap: var(--grid-gutter);
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--grid-row-gap);

    @include tablet-portrait {
        grid-template-columns: repeat(3, 1fr);
    }

    @include tablet {
        grid-template-columns: repeat(4, 1fr);
    }
}

.block-auth-user-stampcard__last-sticker {
    @include fluid('padding', 20px, 10px, 20px);
    @include fluid('column-gap', 35px, 46px, 76px);

    display: flex;

    flex-direction: column;
    border-radius: var(--b-radius--full);
    background-color: var(--c-grey--light);
    grid-column-end: span 2;
    grid-column-start: 1;
    row-gap: 30px;

    @include tablet-portrait {
        flex-direction: row;
        grid-column-end: span 3;
    }

    @include tablet {
        flex-direction: row;
        grid-column-end: span 4;
    }

    @include desktop {
        margin-top: 30px;
    }
}

.block-auth-user-stampcard__last-sticker-sticker {
    min-width: 100%;
    align-self: center;

    @include mobile {
        min-width: 229px;
        max-width: 229px;
    }

    @include tablet-portrait {
        @include fluid('min-width', 116px, 126px, 229px);
        max-width: unset;
    }
}

.block-auth-user-stampcard__last-sticker-description {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
    row-gap: 20px;
    text-align: center;

    @include tablet-portrait {
        @include fluid('padding-right', 0px, 45px, 117px);
        padding-bottom: 0;
        text-align: inherit;
    }
}

.block-auth-user-stampcard__last-sticker-text {
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h3);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h3);
}

.block-auth-user-stampcard__tabs {
    display: flex;
    width: 100%;
    column-gap: 10px;
}

.block-auth-user-stampcard__leaderboard,
.block-auth-user-stampcard__stampcard {
    display: none;

    &.is-visible {
        display: block;
    }
}
</style>

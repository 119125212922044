<template>
  <div
    class="block-auth-registration"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-auth-registration__inner">
      <AtomTextHeading
        v-if="title"
        :text="title"
        font-size="h3"
        class="block-auth-registration__title"
      />

      <AtomTextRichtext
        v-if="text"
        :html="text"
        font-size="h5"
        class="block-auth-registration__text"
      />

      <div class="block-auth-registration__form">
        <UtilRendererForm
          v-if="fields?.length > 0"
          :fields="fields"
          :submit-text="submitText"
          :button-alignment="buttonAlignment"
          :success-message="successMessageToUse"
          :additional-link="link"
          :additional-link-text="linkText"
          :error-message="errorMessage"
          :callback="submitCallback"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    /*
        Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    confirmedSuccessMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },

    endpoint: {
        type: String,
        default: '',
    },

    /*
      Content
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    /*
      Additional Link
    */
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },
});

const { endpoint, successMessage, confirmedSuccessMessage } = toRefs(props);
const { locale } = useI18n();
const successMessageToUse = ref(successMessage.value);

const submitCallback = async (data) => {
    const { data: registrationResult, error } = await useFetch(endpoint.value, {
        method: 'post',
        body: {
            ...data,
            language: locale.value,
        },
    });

    successMessageToUse.value = registrationResult.value?.confirmed
        ? confirmedSuccessMessage.value
        : successMessage.value;

    if (error.value) {
        useSentryError(error.value, {
            useThrow: true,
            payload: data,
            response: registrationResult.value,
        });
    }
};
</script>

<style lang="scss" scoped>
.block-auth-registration {
    @include wrapper-center;

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
    }
}

.block-auth-registration__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);
    @include default-content-columns('medium');
    position: relative;

    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
}

.block-auth-registration__text,
.block-auth-registration__title {
    width: 100%;
    max-width: 641px;
}
</style>

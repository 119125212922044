<template>
  <div class="block-auth-user-leaderboard">
    <div class="block-auth-user-leaderboard__filters">
      <BlockFilterTabs
        v-if="filterTabs?.length > 0"
        :tabs="filterTabs"
        :initial-active-tab="filterTabs[0].name"
        @on-tab-click="handleFilter"
      />
    </div>
    <div class="block-auth-user-leaderboard__list">
      <div
        v-for="(topuser, index) in topUsers"
        :key="`leaderboard-top-user-${Math.random() + index}`"
        class="block-auth-user-leaderboard__entry"
        :class="{
          'is-logged-in-user': topuser.isMe,
        }"
      >
        <div
          v-for="(column, columnIndex) in columns"
          :key="`leaderboard-entry-column-${Math.random() + columnIndex}`"
          class="block-auth-user-leaderboard__list-column"
          :style="`--column-width: ${column.width};`"
        >
          <div class="block-auth-user-leaderboard__header">
            {{ column.label }}
          </div>

          <div class="block-auth-user-leaderboard__value">
            <span
              v-if="column.content === '{{username}}'
                && topuser.isMe && index === topUsers.length - 1"
            >{{ translatedYou }}</span>
            <span v-else>{{ compileWithHandlebars(column.content, topuser) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    columns: {
        type: Array,
        required: true,
    },

    filterTabs: {
        type: Array,
        default: () => [],
    },

    dummyData: {
        type: Array,
        default: () => [],
    },

    translatedYou: {
        type: String,
        default: '',
    },
});

const range = ref('overall');
const {
    data: topUsers,
    error,
} = useLazyFetch(
    '/api/codes/leaderboard',
    {
        server: false,
        method: 'POST',
        body: {
            range,
        },
    },
);

watch(() => error.value, (e) => {
    if (e) {
        useSentryError(e, {
            useThrow: false,
            payload: { range: range.value },
            response: topUsers.value,
        });
    }
});

const handleFilter = (event) => {
    range.value = event;
};
</script>

<style lang="scss" scoped>
.block-auth-user-leaderboard {
    @include fluid('font-size', 15px, 12px, 15px);
    @include fluid('line-height', 18px, 17px, 18px);

    --horizonal-padding: 0;
    width: calc(100% + var(--horizontal-padding) * 2);
    padding-right: var(--horizontal-padding);
    padding-left: var(--horizontal-padding);
    font-family: var(--f-family--primary);
    font-size: 15px;
    font-weight: var(--f-weight--400);
    transform: translateX(calc(var(--horizontal-padding) * -1));

    @include tablet {
        @include fluid('--horizontal-padding', 0px, 20px, 37px);
    }
}

.block-auth-user-leaderboard__filters {
    margin-bottom: 10px;
}

.block-auth-user-leaderboard__header {
    display: flex;
    align-items: center;
    color: var(--c-primary);
    font-weight: var(--f-weight--900);
    white-space: nowrap;

    @include tablet {
        @include fluid('padding-top', 0px, 5px, 11px);
        @include fluid('padding-bottom', 0px, 5px, 11px);

        display: none;
    }
}

.block-auth-user-leaderboard__entry {
    @include grid-layout(1, 12, 12);
    @include fluid('padding-top', 20px, 5px, 11px);
    @include fluid('padding-bottom', 20px, 5px, 11px);

    position: relative;
    border-bottom: 1px solid var(--c-grey);
    row-gap: 20px;

    &:after {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        left: calc(var(--horizontal-padding) * -1);
        width: calc(100% + var(--horizontal-padding) * 2);
        height: calc(100% - 2px);
        border-radius: var(--b-radius--medium);
        content: '';
        pointer-events: none;

        @include tablet {
            @include fluid('height', 27px, 27px, 41px);
        }
    }

    &:last-child {
        padding: 20px;
        border-radius: var(--b-radius--medium);
        margin-top: 20px;
        background: var(--c-primary);
        color: var(--c-white);

        @include tablet {
            @include fluid('padding-top', 20px, 5px, 11px);
            @include fluid('padding-bottom', 20px, 5px, 11px);

            padding-right: 0;
            padding-left: 0;
            border-radius: 0;
            margin-top: 0;
            background: transparent;
        }

        .block-auth-user-leaderboard__header {
            color: var(--c-white);
        }

        &:after {
            background-color: var(--c-primary);
        }
    }

    @include tablet {
        border: none;
        row-gap: 0;

        &:not(:last-child) {
            &:nth-child(even):after {
                background-color: var(--c-grey--light);
            }
        }
    }

    &:first-child {
        .block-auth-user-leaderboard__header {
            @include tablet {
                display: block;
            }
        }
    }

}

.block-auth-user-leaderboard__list-column {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    @include tablet {
        flex-direction: column;
        grid-column-end: span var(--column-width);
    }
}

</style>

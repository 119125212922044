<template>
  <UtilRouteLink
    :link="link"
    class="block-card-cta"
    :class="{
      'is-external-link': isExternalLink,
    }"
  >
    <div class="block-card-cta__indicator">
      <IonIcon
        icon-name="arrow-right"
        :icon-color="backgroundColor"
      />
    </div>

    <div class="block-card-cta__content">
      <div class="block-card-cta__texts">
        <AtomTextHeading
          v-if="title"
          font-size="h3"
          html-tag="h3"
          :text="title"
        />

        <p class="block-card-cta__text">
          {{ text }}
        </p>
      </div>

      <div class="block-card-cta__image">
        <AtomImage
          v-if="image?.src"
          :image="image"
          behaviour="cover"
        />
      </div>
    </div>
  </UtilRouteLink>
</template>

<script setup>
const props = defineProps({
    /*
      Styles
    */
    backgroundColor: {
        type: String,
        default: 'var(--c-white)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    buttonBorderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },

    accentColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
      Content
    */
    title: {
        type: String,
        default: null,
    },

    text: {
        type: String,
        default: null,
    },

    image: {
        type: Object,
        default: () => ({}),
    },

    link: {
        type: String,
        required: true,
    },
});

const {
    backgroundColor,
    borderRadius,
    buttonBorderRadius,
    link,
} = toRefs(props);

const isExternalLink = computed(
    () => link.value?.includes('http://')
    || link.value?.includes('https://')
    || link.value?.includes('mailto:')
    || link.value?.includes('tel:'),
);

</script>

<style lang="scss" scoped>
.block-card-cta__indicator {
    @include fluid('min-width', 30px, 30px, 69px, false);
    @include fluid('min-height', 30px, 30px, 69px, false);

    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    border-radius: var(--button-border-radius);
    background-color: v-bind(accentColor);
    transition: transform 0.3s var(--ease-out--back);

    .is-external-link & {
        transform: rotate(-45deg);
    }

    ::v-deep(.ion-icon__svg) {
        @include fluid('width', 12px, 12px, 17px);
    }
}

.block-card-cta {
    @include fluid('padding-left', 15px, 32px, 58px, false);
    @include fluid('padding-right', 15px, 32px, 58px, false);
    @include fluid('padding-top', 15px, 30px, 40px, false);
    @include fluid('padding-bottom', 15px, 30px, 40px, false);
    @include fluid('row-gap', 10px, 10px, 60px, false);
    @include fluid('min-height', 290px, 325px, 547px, false);

    --button-border-radius: v-bind(buttonBorderRadius);

    display: flex;
    flex-direction: column;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    cursor: pointer;
    transition: transform 0.3s var(--ease-out--back);

    @include hover {
        .block-card-cta__indicator {
            transform: scale(1.035);
        }
    }

    &.is-external-link {
        @include hover {
            .block-card-cta__indicator {
                transform: scale(1.035) rotate(-45deg);
            }
        }
    }
}

.block-card-cta__image {
    @include fluid('width', 55px, 55px, 134px);
    @include fluid('height', 55px, 55px, 134px);

    overflow: hidden;
    border-radius: var(--button-border-radius);
    margin-top: 30px;

    @include tablet {
        margin-top: 0;
    }
}

.block-card-cta__content {
    @include fluid('row-gap', 10px, 10px, 20px, false);

    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    ::v-deep(.is-size-h3) {
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);

        @include tablet {
            font-size: var(--f-size--h3);
            line-height: var(--l-height--h3);
        }
    }
}

.block-card-cta__texts {
    @include fluid('row-gap', 10px, 10px, 20px, false);

    display: flex;
    flex-direction: column;
}

.block-card-cta__text {
    @include fluid('--f-size--p', 16px, 12px, 20px);
    @include fluid('--l-height--p', 22px, 17px, 26.9px);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--p);
}
</style>

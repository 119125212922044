<template>
  <div
    class="block-play-codes"
    :class="{
      'is-on-grid': isOnGrid,
      'has-scanner': scannerContent,
      'is-narrow': isNarrow,
    }"
  >
    <div class="block-play-codes__inner">
      <AtomTextHeading
        v-if="title"
        html-tag="h2"
        :text="title"
        class="block-play-codes__title"
        font-size="h4"
      />
      <div class="block-play-codes__content">
        <div
          v-if="scannerContent"
          class="block-play-codes__column"
        >
          <BlockScanCodes
            v-bind="scannerContent"
            :is-disabled="collectedCodes?.length >= maxCodeRows"
            @on-scan="addScannedCode"
          />
        </div>

        <div class="block-play-codes__column">
          <div
            v-if="formTitle"
            class="block-play-codes__form-title"
          >
            {{ formTitle }}
          </div>

          <form @submit.prevent>
            <div
              v-if="isMounted"
              name="list-fade"
              tag="div"
              class="transition-list-container"
            >
              <AtomInputCode
                v-for="(code, index) in codeRows"
                :key="code._uid"
                v-bind="code"
                ref="codeInputRefs"
                :label="`Code ${index + 1}`"
                :is-invalid="getInputState(code.value)"
                :has-remove="codeRows.length > 1"
                @on-remove="removeRow(index)"
                @set-input="addValue($event, index)"
              />

              <div v-if="hasDuplicates" class="block-play-codes__duplicate-notice">
                {{ duplicateInfoText }}
              </div>

              <div v-if="checkedCodes?.includes(false)" class="block-play-codes__duplicate-notice">
                {{ invalidCodesText }}
              </div>

              <div
                key="list-button"
                class="block-play-codes__controls"
              >
                <AtomButtonMore
                  :total-item-amount="maxCodeRows"
                  :current-item-amount="codeRows.length"
                  @on-load-more="addRow"
                />
              </div>
            </div>

            <div class="block-play-codes__submit">
              <AtomButton
                :text="submitText"
                :is-disabled="collectedCodes?.length === 0
                  || checkedCodes?.includes(false)
                  || isLoading
                  || hasDuplicates"
                :callback="playCodes"
              />
            </div>
          </form>
        </div>
      </div>

      <transition name="fade-in">
        <AtomPopoverNotification
          v-if="popupVisible"
          position="absolute"
          :text="popupMessage"
          :use-click-outside="true"
          :type="popupType"
          @on-hide="popupVisible = false"
        />
      </transition>
    </div>

    <AtomLoaderLogo
      :is-loading="isLoading"
      :text="loadingTitle"
      :subtitle="loadingSubtitle"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    isNarrow: {
        type: Boolean,
        default: false,
    },

    /*
        Content
    */
    title: {
        type: String,
        default: '',
    },

    formTitle: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },

    loadingTitle: {
        type: String,
        default: '',
    },

    loadingSubtitle: {
        type: String,
        default: '',
    },

    /*
        Addons
    */
    addons: {
        type: Array,
        default: () => [],
    },

    /*
        Settings
    */
    initialCodeRows: {
        type: Number,
        default: 3,
    },

    maxCodeRows: {
        type: Number,
        default: 10,
    },

    codeLength: {
        type: Number,
        default: 8,
    },

    placeholder: {
        type: String,
        default: '',
    },

    mask: {
        type: String,
        default: null,
    },

    errorMessage: {
        type: String,
        default: '',
    },

    duplicateInfoText: {
        type: String,
        default: '',
    },

    invalidCodesText: {
        type: String,
        default: '',
    },
});

const {
    initialCodeRows,
    placeholder,
    codeLength,
    maxCodeRows,
    mask,
} = toRefs(props);

/*
    Addons
*/
const scannerContent = computed(() => {
    const addon = props.addons?.find((searchAddon) => searchAddon.name === 'scanCodes');
    return addon?.content || null;
});

/*
    Codes
*/
const codeRows = ref([]);

const checkedCodes = ref([]);
const resetErrors = () => {
    checkedCodes.value = [];
};

/* Initialize code rows */
for (let i = 0; i < initialCodeRows.value; i += 1) {
    codeRows.value.push(
        {
            _uid: getUid(),
            value: '',
            name: `code-${i + 1}`,
            codeLength: codeLength.value,
            placeholder: placeholder.value,
            mask: mask.value,
        },
    );
}

/* Remove a row */
const removeRow = (index) => {
    codeRows.value.splice(index, 1);
    checkedCodes.value.splice(index, 1);
};

/* Add a row */
const addRow = () => {
    if (codeRows.value.length < props.maxCodeRows) {
        codeRows.value.push(
            {
                _uid: getUid(),
                value: '',
                name: `code-${codeRows.value.length + 1}`,
                codeLength: codeLength.value,
                placeholder: placeholder.value,
                mask: mask.value,
            },
        );
    }
};

/*
    Add value to specific codeRow
*/
const addValue = (value, index) => {
    resetErrors();
    codeRows.value[index].value = value.value;
};

/*
    Codes only
*/
const collectedCodes = computed(() => {
    // Filter out empty codes and codes that are not the correct length
    const filteredCodes = codeRows.value
        .map((row) => row.value)
        .filter((value) => value.length > 0 && value.length === codeLength.value);

    if (!filteredCodes?.length) return [];

    // Remove duplicates
    return filteredCodes;
});

/*
    Check for code cookie and prefill the form if available
*/
const wincodeCookie = useCookie('wincode');

onMounted(() => {
    const storedWinCode = wincodeCookie.value;

    if (storedWinCode) {
        codeRows.value[0] = {
            _uid: getUid(),
            name: 'code-0',
            value: `${storedWinCode}`,
            initialValue: `${storedWinCode}`,
            codeLength: codeLength.value,
            placeholder: placeholder.value,
            mask: mask.value,
        };
    }
});

onBeforeUnmount(() => {
    wincodeCookie.value = null;
});

/*
    Scanner
*/
const codeInputRefs = ref(null);

const addScannedCode = (input) => {
    const url = new URL(input);
    const code = url.searchParams.get('code');

    if (codeRows.value.length < maxCodeRows.value && !collectedCodes.value.includes(code)) {
        // Check if there are free slots and if so add the code there, otherwise add a new row
        const freeSlot = codeRows.value.findIndex((row) => row.value.length === 0);

        if (freeSlot !== -1) {
            codeInputRefs.value[freeSlot].inputValue = code;
        } else {
            codeRows.value.push(
                {
                    _uid: getUid(),
                    name: `code-${codeRows.value.length + 1}`,
                    value: code,
                    initialValue: code,
                    codeLength: codeLength.value,
                    placeholder: placeholder.value,
                    mask: mask.value,
                },
            );
        }
    }
};

/*
    Call code checker
*/
const popupVisible = ref(false);
const popupType = 'error';
const popupMessage = props.errorMessage;

const checkCodes = async () => {
    try {
        checkedCodes.value = await useCheckCodes({
            codes: collectedCodes.value,
        }) || [];
    } catch (e) {
        checkedCodes.value = [];
        popupVisible.value = true;
    }
};

/*
    Play codes
*/
const isLoading = ref(false);
const playCodes = async () => {
    await checkCodes();

    if (checkedCodes.value.includes(false)) return;

    isLoading.value = true;

    try {
        await sleep(2000);
        await usePlayCodes({
            codes: collectedCodes.value,
        });
    } catch (e) {
        popupVisible.value = true;
    } finally {
        isLoading.value = false;
    }
};

/*
    Mounted
*/
const isMounted = ref(false);
onMounted(() => {
    isMounted.value = true;
});

/*
    Input state
*/
const getInputState = (code) => {
    if (!collectedCodes.value?.length) {
        return false;
    }

    const codeIndex = collectedCodes.value.findIndex(
        (collectedCode) => collectedCode === code,
    );
    return checkedCodes.value[codeIndex] === false;
};

/*
    Duplicates
*/
const hasDuplicates = computed(() => {
    const codes = collectedCodes.value;
    return codes.length !== new Set(codes).size;
});

</script>

<style lang="scss" scoped>
.block-play-codes__content,
.block-play-codes__title {
    @include tablet {
        grid-column-end: span 2;
    }
}

.block-play-codes__content {
    @include grid-layout(1, 2, 2, 0px);

    row-gap: 30px;
}

.block-play-codes__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);

    display: flex;
    flex-direction: column;
}

.block-play-codes {
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--medium);

        &:not(.is-narrow) {
            .block-play-codes__inner {
                @include default-content-columns();
            }
        }

        &.is-narrow,
        &:not(.has-scanner) {
            .block-play-codes__inner {
                @include default-content-columns('narrow');
            }

            .block-play-codes__content {
                @include grid-layout(1, 1, 1);
            }
        }
    }

}

.block-play-codes__form-title {
    margin-bottom: 10px;
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--value);
    font-weight: var(--f-weight--900);
    line-height: var(--l-height--value);
}

.block-play-codes__column {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    @include tablet {
        row-gap: 15px;
    }

    @include desktop {
        row-gap: 10px;
    }
}

.block-play-codes__controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.transition-list-container {
    position: relative;
    padding: 0;

    .atom-input-code__group {
        margin-bottom: 10px;
    }
}

.block-play-codes__submit {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

/*
    Fade in transition
*/
.list-fade-leave-active {
    position: absolute;
}

.list-fade-move,
.list-fade-enter-active,
.list-fade-leave-active {
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.list-fade-enter-from,
.list-fade-leave-to {
    opacity: 0;
}

.block-play-codes__duplicate-notice {
    margin-top: 20px;
    color: var(--c-error);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--description);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--description);
}
</style>

<template>
  <div
    id="block-auth-user-wins"
    class="block-auth-user-wins"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div class="block-auth-user-wins__inner">
      <AtomTextHeading
        v-if="title"
        html-tag="h2"
        :text="title"
        class="block-auth-user-wins__title"
        font-size="h3"
      />

      <BlockFilterTabs
        v-if="filterTabs?.length > 0"
        :tabs="filterTabs"
        class="block-auth-user-wins__tabs"
        :initial-active-tab="filterTabs[0].name"
        @on-tab-click="handleTabClick"
      />

      <BlockTableRows
        :rows="data"
        :is-skeleton="disabledSkeleton ? false : pending"
        :no-results-text="currentTab?.noResultsText"
        :no-results-cta-text="currentTab?.noResultsCtaText"
        :no-results-cta-link="currentTab?.noResultsCtaLink"
        @on-redeem-prize="onRedeem($event)"
      />

      <transition name="fade-in">
        <BlockPaginationNumbers
          v-if="pages > 1"
          ref="paginationRef"
          class="block-auth-user-wins__pagination"
          :total-pages="pages"
          @on-page-change="handlePageChange"
        />
      </transition>
    </div>

    <BlockOverlayRedeem
      :is-open="isOverlayOpen"
      :title="overlayTitle"
      :text="overlayText"
      :submit-text="overlaySubmitText"
      :success-message="overlaySuccessMessage"
      :error-message="overlayErrorMessage"
      :form-fields="overlayFormFields"
      :code="codeToRedeem"
      @on-close="onCloseOverlay($event)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    /* Content */
    title: {
        type: String,
        default: '',
    },

    /*
        Settings
    */
    paginationSize: {
        type: Number,
        default: 5,
    },

    pages: {
        type: Number,
        default: 10,
    },

    filterTabs: {
        type: Array,
        default: () => [],
    },

    /*
        Storyblok Dummy Data
    */
    dummyData: {
        type: Array,
        default: null,
    },

    /*
        Redeem Overlay
    */
    overlayTitle: {
        type: String,
        default: '',
    },

    overlayText: {
        type: String,
        default: '',
    },

    overlaySubmitText: {
        type: String,
        default: '',
    },

    overlaySuccessMessage: {
        type: String,
        default: '',
    },

    overlayErrorMessage: {
        type: String,
        default: '',
    },

    overlayFormFields: {
        type: Array,
        default: () => [],
    },
});

/* Module default content */
const { content: moduleContent } = getModuleData('yourWins');
const { settings: columnsSettings } = moduleContent;

/* Data */
const { public: publicRuntimeConfig } = useRuntimeConfig();
const data = ref(publicRuntimeConfig.IS_STORYBOOK ? props.dummyData : []);
const pages = ref(publicRuntimeConfig.IS_STORYBOOK ? props.pages : 0);
const currentPage = ref(1);
const { locale } = useI18n();
const onlyWins = ref(false);

const { dayjs } = useDayJs();

const {
    pending,
    error,
    data: response,
    refresh,
} = useLazyFetch(
    '/api/codes/played',
    {
        server: false,
        method: 'POST',
        body: {
            lang: locale,
            page: currentPage,
            pageSize: props.paginationSize,
            onlyWins,
        },
        transform: (res) => {
            if (!res || !res?.pagination) return;

            const { data: codes, pagination } = res;
            pages.value = Math.ceil(pagination.total / pagination.pageSize);
            data.value = [];

            codes?.forEach((code) => {
                const replaceObj = {
                    ...code,
                    ...code.prize,
                    played_at: code.played_at ? dayjs(code.played_at).format('DD / MM / YYYY') : '',
                    redeemed_at: code.prize?.redeemed_at ? dayjs(code.prize?.redeemed_at).format('DD / MM / YYYY') : '',
                };

                delete replaceObj.prize;
                let columnsToInsert = [];

                const { prize } = code;

                switch (prize?.key) {
                case 'instant':
                case 'interval':
                    columnsToInsert = JSON.parse(JSON.stringify(columnsSettings.instantColumns));
                    break;
                case 'points':
                    columnsToInsert = JSON.parse(JSON.stringify(columnsSettings.pointsColumns));
                    break;
                case 'digital':
                    columnsToInsert = JSON.parse(JSON.stringify(columnsSettings.digitalColumns));
                    break;
                default:
                    columnsToInsert = JSON.parse(JSON.stringify(columnsSettings.bummerColumns));
                    break;
                }

                columnsToInsert.map((column) => {
                    const { content } = column;

                    if (content.buttonAction === 'redeemPrize') {
                        if (replaceObj.redeemed_at) {
                            delete content.buttonAction;
                            delete content.buttonText;
                            delete content.buttonValue;
                        } else {
                            delete content.subtitle;
                        }
                    }

                    Object.entries(content).forEach(([key, value]) => {
                        if (typeof content[key] === 'string') {
                            content[key] = compileWithHandlebars(value, replaceObj);
                        } else {
                            const deepContent = content[key];
                            Object.entries(deepContent).forEach(
                                ([deepKey, deepValue]) => {
                                    deepContent[deepKey] = compileWithHandlebars(
                                        deepValue,
                                        replaceObj,
                                    );
                                },
                            );
                        }
                    });

                    return column;
                });

                data.value.push({
                    columns: columnsToInsert,
                });
            });
        },
    },
);

watch(() => error.value, (e) => {
    if (e) {
        useSentryError(e, {
            useThrow: false,
            payload: {
                lang: locale.value,
                page: currentPage.value,
                pageSize: props.paginationSize,
                onlyWins: onlyWins.value,
            },
            response: response?.value,
        });
    }
});

const currentTabIndex = ref(0);
const disabledSkeleton = ref(false);
const paginationRef = ref(null);

const handleTabClick = (event) => {
    disabledSkeleton.value = true;
    onlyWins.value = event === 'wins';
    currentTabIndex.value = props.filterTabs.findIndex((tab) => tab.name === event);
    currentPage.value = 1;
    paginationRef.value?.reset();
};

const handlePageChange = (page) => {
    currentPage.value = page;

    const lenisInstance = getLenis('global');

    if (lenisInstance) {
        const { lenis } = lenisInstance;
        lenis?.scrollTo(
            0,
        );
    }
};

/*
    Redeem
*/
const isOverlayOpen = ref(false);
const codeToRedeem = ref('');

const onRedeem = async (event) => {
    isOverlayOpen.value = true;
    codeToRedeem.value = event;
    refresh();
};

const onCloseOverlay = (refetch = false) => {
    isOverlayOpen.value = false;

    if (refetch) {
        refresh();
    }
};

/*
    Currenttab
*/
const currentTab = computed(() => {
    if (!props.filterTabs?.length) return {};

    return props.filterTabs[currentTabIndex.value];
});
</script>

<style lang="scss" scoped>
.block-auth-user-wins {
    &.is-on-grid {
        @include wrapper-layout();
        @include grid-layout();

        margin-bottom: var(--m-bottom--medium);
    }
}

.block-auth-user-wins__inner {
    @include box-default;

    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @include tablet {
        row-gap: 20px;
    }

    .is-on-grid & {
        @include default-content-columns('wide');
    }
}

.block-auth-user-freecodes__pagination {
    max-width: 500px;

    @include tablet-portrait {
        align-self: center;
    }
}

.block-auth-user-wins__tabs {
    @include fluid('--parent-padding', 38px, 32px, 60px, false);
    width: calc(100% + var(--parent-padding) * 2);
    margin-left: calc(var(--parent-padding) * -1);

    ::v-deep(.atom-tab) {
        &:first-child {
            margin-left: var(--parent-padding);
        }

        &:last-child {
            margin-right: var(--parent-padding);
        }
    }
}
</style>

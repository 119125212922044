<template>
  <div class="block-table-rows__wrapper">
    <div v-if="isSkeleton" class="block-table-rows__skeleton">
      <div
        v-for="i in 5"
        :key="`table-row-skeleton-${i}`"
        class="block-table-rows__skeleton-row is-skeleton"
      />
    </div>

    <div
      v-else
      class="block-table-rows"
    >
      <BlockRowColumns
        v-for="(row, index) of rows"
        :key="`${Math.random() * index}`"
        :columns="row.columns"
        :text-color="textColor"
        :grid-length="gridLength"
        :grid-gutter="gridGutter"
      >
        <template
          v-for="(column, columnIndex) of row.columns"
          :key="`column-${Math.random() + columnIndex}`"
          #[`column-${columnIndex}`]
        >
          <div
            v-if="column.content?.title"
            class="block-table-rows__column-title"
          >
            {{ column.content.title }}
          </div>

          <div
            v-if="column.content?.description"
            class="block-table-rows__column-description"
          >
            {{ column.content.description }}
          </div>

          <div
            v-if="column.content?.subtitle"
            class="block-table-rows__column-subtitle"
          >
            {{ column.content.subtitle }}
          </div>

          <UtilRouteLink
            v-if="column.content?.buttonLink && column.content?.buttonText"
            class="block-table-rows__column-button-link"
            :link="column.content.buttonLink"
          >
            <AtomButton :text="column.content.buttonText" />
          </UtilRouteLink>

          <div
            v-if="column.content?.buttonAction && column.content?.buttonText"
            class="block-table-rows__column-button-action"
          >
            <AtomButton
              :text="column.content.buttonText"
              :is-disabled="isDisabled"
              @click="executeAction(column.content.buttonAction, column.content.buttonValue)"
            />
          </div>

          <div
            v-if="column.content?.imageText"
            class="block-table-rows__column-image-text"
          >
            <div class="block-table-rows__column-image">
              <AtomImage
                v-if="column.content.imageText.image"
                behaviour="cover"
                :image="{
                  src: column.content.imageText.image,
                  alt: column.content.imageText.text,
                }"
              />
            </div>

            <div class="block-table-rows__column-image-text-text">
              {{ column.content.imageText.text }}
            </div>
          </div>
        </template>
      </BlockRowColumns>

      <div v-if="rows.length === 0 && !isSkeleton" class="block-table-rows__empty">
        <AtomTextRichtext v-if="noResultsText" :html="noResultsText" />
        <UtilRouteLink v-if="noResultsCtaLink" :link="noResultsCtaLink">
          <AtomButton :text="noResultsCtaText" />
        </UtilRouteLink>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    rows: {
        type: Array,
        default: () => [],
    },

    /*
        Styles
    */
    textColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
        Settings
    */
    gridLength: {
        type: Number,
        default: 13,
    },

    gridGutter: {
        type: String,
        default: 'var(--grid-gutter)',
    },

    noResultsText: {
        type: String,
        default: null,
    },

    noResultsCtaText: {
        type: String,
        default: null,
    },

    noResultsCtaLink: {
        type: String,
        default: null,
    },

    noResultsCtaIcon: {
        type: String,
        default: null,
    },

    isSkeleton: {
        type: Boolean,
        default: false,
    },
});

const isDisabled = ref(false);
const emit = defineEmits(['on-redeem-prize']);

const executeAction = async (action, value) => {
    isDisabled.value = true;
    switch (action) {
    case 'playCode':
        try {
            await usePlayCodes({
                codes: [value],
            });
        } catch (e) {
            isDisabled.value = false;
        } finally {
            isDisabled.value = false;
        }
        break;
    case 'redeemPrize':
        emit('on-redeem-prize', value);
        isDisabled.value = false;
        break;
    default:
        break;
    }
};
</script>

<style lang="scss" scoped>
.block-table-rows,
.block-table-rows__skeleton {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    row-gap: 40px;

    @include tablet {
        row-gap: 20px;
    }
}

.block-table-rows__skeleton {
    padding-top: 20px;
}

.block-table-rows__skeleton
.block-table-rows__skeleton-row {
    width: 100%;
    height: 50px;

    @include skeleton-loading(var(--b-radius--small));
}

/*
  Title
*/
.block-table-rows__column-title {
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h4);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h4);
}

/*
  Subtitle
*/
.block-table-rows__column-subtitle {
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h5);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h5);

    @include tablet {
        font-size: var(--f-size--h5);
        font-weight: var(--f-weight--500);
        line-height: var(--l-height--h5);
    }
}

.block-table-rows__column-description {
    font-family: var(--f-family--primary);
    font-size: var(--f-size--description);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--description);
}

/*
  Image Text
*/
.block-table-rows__column-image-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h4);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h4);
}

.block-table-rows__column-image {
    @include fluid('width', 118px, 75px, 118px, false);
    @include fluid('height', 118px, 75px, 118px, false);
    @include fluid('min-width', 118px, 75px, 118px, false);
    @include fluid('min-height', 118px, 75px, 118px, false);

    overflow: hidden;
    border: 1px solid var(--c-grey);
    border-radius: var(--b-radius--full);
}

.block-table-rows__empty {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--p);
    row-gap: 15px;
}
</style>

/* eslint-disable import/no-extraneous-dependencies */
/*
    Important Note:
    Directives are currently not working inside our storybook package.
    Thats why we have to use the vanilla version of the maska package.
*/
import { MaskInput } from 'maska';

export default (element, options = {}) => {
    const maskInput = new MaskInput(element, options);

    onUnmounted(() => {
        if (maskInput && maskInput.destroy) {
            maskInput.destroy();
        }
    });
};

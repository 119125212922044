<template>
  <Teleport to="body">
    <div
      class="block-overlay-confirmation"
      :class="{ 'is-visible': isOpen }"
    >
      <button
        class="block-overlay-confirmation__close"
        type="button"
        @click="reject"
      >
        <ClientOnly>
          <IonIcon
            icon-name="close"
            icon-color="var(--c-white)"
            icon-size="10px"
          />
        </ClientOnly>
      </button>

      <div class="block-overlay-confirmation__grid">
        <div class="block-overlay-confirmation__inner">
          <AtomTextHeading
            v-if="title"
            :text="title"
            class="block-overlay-confirmation__title"
            font-size="h3"
          />

          <AtomTextRichtext
            v-if="text"
            class="block-overlay-confirmation__text"
            :html="text"
          />

          <div class="block-overlay-confirmation__buttons">
            <AtomButton
              :text="rejectText"
              background-color="var(--c-grey--light)"
              border-color="var(--c-grey--light)"
              text-color="var(--c-primary)"
              @click="reject"
            />

            <AtomButton
              :text="confirmText"
              @click="confirm"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    confirmText: {
        type: String,
        default: 'Confirm',
    },

    rejectText: {
        type: String,
        default: 'Reject',
    },

    isOpen: {
        type: Boolean,
        default: false,
    },
});

const resolvePromise = ref(null);
const isLocked = ref(false);
const createPromise = () => new Promise((resolve) => {
    resolvePromise.value = resolve;
    isLocked.value = true;
});

/*
    Handlers
*/
const confirm = () => {
    if (!resolvePromise.value) return;
    resolvePromise.value(true);
    isLocked.value = false;
};

const reject = () => {
    if (!resolvePromise.value) return;
    resolvePromise.value(false);
    isLocked.value = false;
};

/*
    Expose
*/
defineExpose({
    createPromise,
});

/*
  Scroll lock
*/
watch(() => isLocked.value, (newValue) => {
    if (newValue) {
        stopLenis('global');
    } else {
        startLenis('global');
    }
});
</script>

<style lang="scss" scoped>
.block-overlay-confirmation {
    @include z-index('confirmOverlay');

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.95);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.is-visible {
        opacity: 1;
        pointer-events: all;
    }
}

.block-overlay-confirmation__grid {
    @include wrapper-layout;
    @include grid-layout();

    height: 100%;
    align-content: center;
}

.block-overlay-confirmation__inner {
    @include default-content-columns('medium');
    @include grid-layout(1, 1, 12);

    height: 100%;
}

.block-overlay-confirmation__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    column-gap: 10px;

    @include desktop {
        grid-column-end: span 12;
    }
}

.block-overlay-confirmation__title,
.block-overlay-confirmation__text {

    @include desktop {
        grid-column-end: span 8;
    }
}

.block-overlay-confirmation__close {
    @include z-index('confirmOverlay');

    position: absolute;
    top: 30px;
    right: var(--page-padding);
    display: flex;
    width: 69px;
    height: 69px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background: var(--c-primary);

    @include fluid('top', 30px, 60px, 60px);

    @include tablet {
        right: 58px;
    }
}
</style>

<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="block-scan-codes__wrapper">
    <div class="block-scan-codes">
      <BlockOverlaySlot
        :is-open="isOverlayOpen"
        @on-close="toggleOverlay"
      >
        <AtomTextHeading
          v-if="overlayTitle"
          :text="overlayTitle"
          font-size="h3"
        />
        <AtomImage
          v-if="overlayImage"
          :image="overlayImage"
          border-radius="var(--b-radius--big)"
        />
        <AtomTextRichtext
          v-if="overlayText"
          :html="overlayText"
        />
        <AtomButton
          v-if="overlayButtonText"
          :text="overlayButtonText"
          @click="toggleOverlay"
        />
      </BlockOverlaySlot>

      <div class="block-scan-codes__content" :class="{ 'is-hidden': isLoading }">
        <IonIcon
          icon-name="camera"
          icon-size="40px"
          class="block-scan-codes__icon"
          :icon-color="textColor"
        />

        <AtomButton
          :text="cameraButtonText"
          class="block-scan-codes__button"
          @click="initScanner"
        />

        <button
          type="button"
          class="block-scan-codes__intro"
          @click="toggleOverlay"
        >
          {{ instructionsButtonText }}
        </button>
      </div>

      <ClientOnly>
        <qrcode-stream
          v-if="loadedCamera"
          class="block-scan-codes__reader"
          :constraints="constraints"
          :class="{ 'is-scanning': isScanning }"
          :torch="torchActive"
          @detect="onDetect($event)"
          @camera-on="onCameraOn($event)"
          @camera-off="onCameraOff()"
        />
      </ClientOnly>

      <transition name="fade-in">
        <div
          v-if="isDisabled"
          class="block-scan-codes__resume"
        >
          <AtomTextHeading
            :text="lockedTitle"
            font-size="h4"
            alignment="center"
          />

          <div class="block-scan-codes__note">
            {{ lockedInformation }}
          </div>
        </div>

        <div
          v-else-if="isPaused"
          class="block-scan-codes__resume"
        >
          <AtomTextHeading
            :text="scannedTitle"
            font-size="h4"
            alignment="center"
          />

          <div class="block-scan-codes__note">
            {{ scannedInformation }}
          </div>

          <AtomButton
            :text="nextButtonText"
            class="block-scan-codes__button__next"
            @click="resumeScanner"
          />
        </div>
      </transition>

      <AtomLoaderLogo
        :is-loading="isLoading"
        position="absolute"
        :text="cameraLoadingText"
        :subtitle="cameraLoadingSubtitle"
      />

      <button
        v-if="hasTorchCapability"
        type="button"
        class="block-scan-codes__camera-torch"
        @click="toggleTorch"
      >
        <IonIcon
          :icon-name="torchActive ? 'bulb-filled' : 'bulb'"
          icon-size="14px"
          icon-color="var(--c-white)"
        />
      </button>
    </div>

    <div
      v-if="currentCamera && cameras.length > 1"
      class="block-scan-codes__settings"
    >
      <button
        type="button"
        class="block-scan-codes__settings-toggle"
        @click="toggleCameraOptions()"
        v-html="settingsButtonText"
      />

      <div
        v-if="cameraOptionsOpen"
        class="block-scan-codes__settings-options"
      >
        <AtomInputSelect
          v-if="activeCameraId && cameraOptions?.length > 0"
          name="activeCamera"
          class="block-scan-codes__choose-camera"
          :label="chooseCameraLabel"
          :options="cameraOptions || []"
          :initial-value="activeCameraId"
          :disable-initial-emit="true"
          @set-input="setCamera($event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import { QrcodeStream } from 'vue-qrcode-reader';

const props = defineProps({
    /*
        Styles
    */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-grey--light)',
    },

    textColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
        Functionalilty
    */
    isDisabled: {
        type: Boolean,
        default: true,
    },

    /*
        Content
    */
    cameraButtonText: {
        type: String,
        default: '',
    },

    nextButtonText: {
        type: String,
        default: '',
    },

    instructionsButtonText: {
        type: String,
        default: '',
    },

    scannedTitle: {
        type: String,
        default: '',
    },

    scannedInformation: {
        type: String,
        default: '',
    },

    lockedTitle: {
        type: String,
        default: '',
    },

    lockedInformation: {
        type: String,
        default: '',
    },

    overlayTitle: {
        type: String,
        default: '',
    },

    overlayImage: {
        type: Object,
        default: null,
    },

    overlayText: {
        type: String,
        default: '',
    },

    overlayButtonText: {
        type: String,
        default: '',
    },

    cameraLoadingText: {
        type: String,
        default: 'Kamera lädt...',
    },

    cameraLoadingSubtitle: {
        type: String,
        default: 'Bitte warten Sie einen Moment',
    },

    /* Additonal texts */
    settingsButtonText: {
        type: String,
        default: 'Probleme beim Scannen? <br>Hier Kameraeinstellungen ändern',
    },

    chooseCameraLabel: {
        type: String,
        default: 'Kamera auswählen',
    },
});

const {
    borderRadius,
    backgroundColor,
    textColor,
} = toRefs(props);

/*
    Scanner Variables
*/
const isScanning = ref(false);
const loadedCamera = ref(false);
const isPaused = ref(false);
const cameraOptionsOpen = ref(false);
const toggleCameraOptions = () => {
    cameraOptionsOpen.value = !cameraOptionsOpen.value;
};

const emit = defineEmits(['on-scan']);

/*
    Pause scanner
*/
const pauseScanner = () => {
    isPaused.value = true;
};

const onDetect = (event) => {
    const code = event?.length > 0 ? event[0].rawValue : null;
    if (code) {
        pauseScanner();
        emit('on-scan', code);
        useStorybookAction('on-scan', code);

        sendEvent({
            event: 'scan',
            value: code,
            category: 'codes',
        });
    }
};

/*
    Start scanner
*/
const isLoading = ref(false);
const cameras = ref([]);
const cameraOptions = computed(() => cameras.value.map((camera, index) => ({
    label: `Kamera ${index + 1}`,
    value: camera.deviceId,
})));

const activeCameraId = ref(null);
const currentCamera = ref(null);
const hasTorchCapability = computed(() => currentCamera.value?.torch);

const constraints = reactive({
    facingMode: 'environment',
    focusMode: 'continuous',
    zoom: 1.5,
});

const setCamera = (event) => {
    isLoading.value = true;
    activeCameraId.value = event.value;
    constraints.deviceId = activeCameraId.value;
};

const torchActive = ref(false);
const toggleTorch = () => {
    isLoading.value = true;
    torchActive.value = !torchActive.value;
    constraints.torch = torchActive.value;
};

/* Please leave this code for now, it will maybe be used in the future
const currentZoom = ref(1);
const hasZoomCapability = computed(() => (!!currentCamera.value?.zoom));
const { throttledExecute } = useThrottledExecute(100);
const setZoom = (event) => {
    if (!hasZoomCapability.value) return;
    throttledExecute(() => {
        constraints.zoom = parseFloat(event.target.value);
    }, 100);
    currentZoom.value = event.target.value;
    constraints.zoom = parseFloat(currentZoom.value);
};

  <input
    v-if="hasZoomCapability"
    :value="currentZoom"
    class="block-scan-codes__camera-option"
    type="range"
    style="top: 100px; width: 100%;"
    min="1"
    max="2"
    step="0.01"
    @change="setZoom($event)"
  />
*/

const onCameraOn = (event) => {
    isLoading.value = false;
    currentCamera.value = event;
    isScanning.value = true;
    activeCameraId.value = event.deviceId;
};

const onCameraOff = () => {
    isLoading.value = true;
};

const startScanner = async () => {
    isLoading.value = true;

    try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // TODO: Check if navigator.mediaDevices.enumerateDevices() is supported
        // Otherwise show an error message or not even show the camera button
        cameras.value = (await navigator.mediaDevices.enumerateDevices()).filter(
            (device) => device.kind === 'videoinput' && !device.label.toLowerCase().includes('front'),
        );

        // Stop all tracks in the stream to release the camera
        stream.getTracks().forEach((track) => track.stop());
        loadedCamera.value = true;
    } catch (e) {
        isLoading.value = false;
    }
};

/*
    Resume scanner
*/
const resumeScanner = async () => {
    isPaused.value = false;
};

/*
    Get cameras
*/
const initScanner = async () => {
    if (isScanning.value) return;
    await startScanner();
};

/*
    Overlay
*/
const isOverlayOpen = ref(false);
const toggleOverlay = () => {
    isOverlayOpen.value = !isOverlayOpen.value;
};
</script>

<style lang="scss" scoped>
.block-scan-codes__settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
    row-gap: 22px;
}

.block-scan-codes__choose-camera {
    z-index: 100;
}

.block-scan-codes__camera-torch {
    position: absolute;
    z-index: 100;
    right: 20px;
    bottom: 15px;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background-color: var(--c-primary);

}

.block-scan-codes__settings-toggle {
    max-width: 250px;
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a--small);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--a--small);
    text-align: center;
    text-decoration: underline;
}

.block-scan-codes {
    @include fluid('padding-bottom', 40px, 20px, 40px);
    @include fluid('padding-top', 40px, 20px, 40px);

    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: v-bind(borderRadius);
    aspect-ratio: 1;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @include tablet-portrait {
        max-width: 360px;
    }

    @include tablet {
        max-width: unset;
    }
}

.block-scan-codes__reader {
    position: absolute !important;
    z-index: 1 !important;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.is-scanning {
        opacity: 1;
        pointer-events: all;
    }
}

.block-scan-codes__settings-options {
    width: 100%;
}

.block-scan-codes__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    &.is-hidden {
        display: none;
    }
}

.block-scan-codes__icon {
    @include fluid('margin-bottom', 25px, 5px, 10px);
}

.block-scan-codes__intro,
.block-scan-codes__note {
    @include fluid-simple('padding-left', 30px, 50px);
    @include fluid-simple('padding-right', 30px, 50px);

    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a--small);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--a--small);
    text-align: center;
    text-decoration: underline;
}

.block-scan-codes__note {
    text-decoration: none
}

.block-scan-codes__resume {
    @include z-index('scannerResume');

    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: v-bind(borderRadius);
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.75);
    row-gap: 10px;

}

.block-scan-codes__button__next {
    margin-top: 10px;
}

#qr-scanner {
    position: relative;
    ::v-deep(div) {
        opacity: 0;
    }
}
</style>

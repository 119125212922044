<template>
  <div
    class="block-list-codeorigins"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div class="block-list-codeorigins__inner">
      <div class="block-list-codeorigins__header">
        <AtomTextHeading
          v-if="title"
          html-tag="h2"
          font-size="h3"
          :allow-hyphens="true"
          :text="title"
        />

        <div class="block-list-codeorigins__buttons">
          <UtilRouteLink
            v-for="button in buttons"
            :key="button.id"
            :link="button.link"
          >
            <AtomButton v-bind="button" />
          </UtilRouteLink>
        </div>
      </div>

      <div class="block-list-codeorigins__grid">
        <AtomTextRichtext
          v-if="text"
          :html="text"
          class="block-list-codeorigins__text"
        />
      </div>

      <div class="block-list-codeorigins__content only-desktop">
        <BlockCardCodeorigin
          v-for="(card, index) of cardsToRender"
          :key="`card-codeorigin-${Math.random() + index}`"
          :is-skeleton="pending"
          v-bind="card"
        />
      </div>

      <div class="block-list-codeorigins__content only-mobile">
        <ClientOnly>
          <swiper-container
            ref="swiperRef"
            class="block-list-codeorigins__swiper"
            :slides-per-view="1.35"
            :breakpoints="{
              500: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
            }"
            :space-between="20"
          >
            <swiper-slide
              v-for="(card, index) of cardsToRender"
              :key="`card-codeorigin-${Math.random() + index}`"
              class="block-list-codeorigins__slide"
            >
              <BlockCardCodeorigin v-bind="card" />
            </swiper-slide>
          </swiper-container>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    /* Content */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    buttons: {
        type: Array,
        default: () => [],
    },

    cards: {
        type: Array,
        default: () => [],
    },
});

const cardsToRender = ref(Array.from({ length: 8 }, () => ({})));
const { locale } = useI18n();
const {
    pending,
    data: response,
    error,
} = useLazyFetch(
    '/api/campaign/get-origins',
    {
        server: false,
        method: 'POST',
        body: {
            locale,
        },

        transform: (data) => {
            const mappedResult = data.data?.map((item) => {
                const { attributes } = item;

                const mappedItem = {
                    backgroundColor: attributes?.color,
                    title: attributes?.name,
                    subtitle: attributes?.subtitle,
                    textColor: '#FFFFFF',
                    image: {
                        alt: attributes?.image?.data?.attributes?.alt,
                        src: attributes?.image?.data?.attributes?.url,
                    },
                };

                return mappedItem;
            });

            cardsToRender.value = mappedResult;
        },
    },
);

watch(() => error.value, (e) => {
    if (e) {
        useSentryError(e, {
            useThrow: false,
            payload: {
                locale: locale.value,
            },
            response: response?.value,
        });
    }
});

/*
    Init Swiperjs
*/
await useSwiperJs();
</script>

<style lang="scss" scoped>
.block-list-codeorigins {

    &.is-on-grid {
        @include wrapper-layout();
        @include grid-layout();
    }
}

.block-list-codeorigins__inner {
    @include box-default();

    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    row-gap: 20px;

    .is-on-grid & {
        @include default-content-columns('wide');
        margin-bottom: var(--m-bottom--big);
    }
}

.block-list-codeorigins__header {
    @include fluid('row-gap', 20px, 20px, 47px, false);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.block-list-codeorigins__grid {
    @include grid-layout(1, 14, 14);
}

.block-list-codeorigins__text {
    margin-bottom: 20px;
    grid-column-end: 1;

    @include tablet {
        grid-column-end: span 10;
    }
}

.block-list-codeorigins__buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
}

.block-list-codeorigins__content {
    @include grid-layout(1, 2, 3);

    &.only-desktop {
        display: none;

        @include tablet {
            display: grid;
        }
    }

    &.only-mobile {
        display: block;

        @include tablet {
            display: none;
        }

        ::part(container) {
            overflow: visible;
        }
    }
}
</style>

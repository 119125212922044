<template>
  <div class="block-row-columns">
    <div class="block-row-columns__titles">
      <div
        v-for="(column, index) of columns"
        :key="`${column.name}-${Math.random() * index}-title`"
        class="block-row-columns__column__title"
        :style="`--column-width: ${column.width};`"
      >
        <div class="grid-row-column__tiles-title">
          {{ column.title }}
        </div>
      </div>
    </div>

    <div
      v-for="(column, index) of columns"
      :key="`${column.name}-${Math.random() * index}`"
      class="block-row-columns__column"
      :class="[
        `is-rendering-mode-${column.renderingMode}`,
      ]"
      :style="`--column-width: ${column.width};`"
    >
      <div class="grid-row-column__title">
        {{ column.title }}
      </div>

      <slot :name="`column-${index}`" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    columns: {
        type: Array,
        default: () => [],
    },

    /*
        Styles
    */
    textColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
        Settings
    */
    gridLength: {
        type: Number,
        default: 13,
    },

    gridGutter: {
        type: String,
        default: 'var(--grid-gutter)',
    },
});

const {
    textColor,
    gridLength,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-row-columns__titles {
    display: none;
    grid-column-end: span var(--grid-length);
}

.grid-row-column__title {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--value);
    font-weight: var(--f-weight--900);
    line-height: var(--l-height--value);

    @include tablet-portrait {
        display: none;
        margin-bottom: 15px;
    }

    .is-rendering-mode-block &{
        margin-bottom: 10px;

        @include tablet-portrait {
            margin-bottom: 0;
        }
    }
}

.block-row-columns__titles,
.block-row-columns {
    --grid-length: v-bind(gridLength);
    position: relative;

    column-gap: v-bind(gridGutter);
    grid-template-columns: repeat(var(--grid-length), 1fr);
}

.block-row-columns {
    display: grid;
    row-gap: 15px;

    &:after {
        position: absolute;
        bottom: -20px;
        width: 100%;
        height: 1px;
        border-radius: 2px;
        background: var(--c-grey);
        content: '';

        @include tablet-portrait {
            bottom: -9.5px;
        }
    }

    &:first-child {
        &:before {
            position: absolute;
            top: -20px;
            width: 100%;
            height: 1px;
            border-radius: 2px;
            background: var(--c-grey);
            content: '';

            @include tablet-portrait {
                top: 24px;
            }
        }
        .block-row-columns__titles {
            @include tablet-portrait {
                display: grid;
            }
        }
        .grid-row-column__title  {
            @include tablet-portrait {
                display: none;
            }
        }
    }
}

.block-row-columns__column__title {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--value);
    font-weight: var(--f-weight--900);
    grid-column-end: span var(--column-width);
    line-height: var(--l-height--value);
}

.block-row-columns__column {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    grid-column-end: span var(--grid-length);

    &.is-rendering-mode-inline {
        flex-direction: row;
        align-items: center;

        @include tablet-portrait {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @include tablet-portrait {
        justify-content: center;
        grid-column-end: span var(--column-width);
    }
}

</style>

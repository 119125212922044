<template>
  <div
    class="block-auth-login-magic"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-auth-login-magic__inner">
      <AtomTextHeading
        v-if="title"
        :text="title"
        font-size="h3"
        class="block-auth-login-magic__title"
      />

      <AtomTextRichtext
        v-if="text"
        :html="text"
        class="block-auth-login-magic__text"
      />

      <div class="block-auth-login-magic__form">
        <UtilRendererForm
          v-if="fields?.length > 0"
          :fields="fields"
          :submit-text="submitText"
          :button-alignment="buttonAlignment"
          :success-message="successMessage"
          :additional-link="link"
          :additional-link-text="linkText"
          :additional-link-tooltip="linkTooltip"
          :error-message="errorMessage"
          :use-sentry-error="false"
          :callback="submitCallback"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
          Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    /*
          Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },

    /*
        Content
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    /*
        Additional Link
    */
    link: {
        type: String,
        default: '',
    },

    linkText: {
        type: String,
        default: '',
    },

    linkTooltip: {
        type: String,
        default: '',
    },

    endpoint: {
        type: String,
        default: '/userportal/auth/forgot-password',
    },
});

const {
    endpoint,
} = toRefs(props);

const { public: publicRuntimeConfig } = useRuntimeConfig();
const submitCallback = async (data) => {
    const mergedData = {
        ...data,
        campaign: parseInt(publicRuntimeConfig.CAMPAIGN, 10),
    };

    const { error } = await useFetch(endpoint.value, {
        method: 'post',
        body: mergedData,
    });

    if (error.value) {
        throw createError(error.value);
    }
};
</script>

<style lang="scss" scoped>
.block-auth-login-magic {
    @include wrapper-center;

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
    }
}

.block-auth-login-magic__inner {
    @include box-default;
    @include fluid('row-gap', 20px, 15px, 20px);
    @include default-content-columns('narrow');
    position: relative;

    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;

    ::v-deep(p) {
        font-size: var(--f-size--h5);
    }
}

.block-auth-login-magic__text,
.block-auth-login-magic__title {
    width: 100%;
    max-width: 641px;
}
</style>

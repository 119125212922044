<template>
  <div
    class="block-card-codeorigin"
    :class="{ 'is-skeleton': isSkeleton }"
  >
    <div class="block-card-codeorigin__image">
      <AtomImage
        v-if="image?.src"
        :image="image"
        behaviour="contain"
      />
    </div>
    <div class="block-card-codeorigin__content">
      <div
        v-if="title"
        class="block-card-codeorigin__content-title"
      >
        {{ title }}
      </div>

      <div
        v-if="subtitle"
        class="block-card-codeorigin__content-subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Styles */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--big-alternate)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-primray)',
    },

    textColor: {
        type: String,
        default: 'var(--c-white)',
    },

    boxShadow: {
        type: String,
        default: 'var(--box-shadow--big)',
    },

    /* Content */
    title: {
        type: String,
        default: '',
    },

    subtitle: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => {},
    },

    isSkeleton: {
        type: Boolean,
        default: false,
    },
});

const {
    borderRadius,
    backgroundColor,
    textColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-card-codeorigin {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: v-bind(borderRadius);
    aspect-ratio: 61 / 42;
    box-shadow: v-bind(boxShadow);

    @include tablet {
        aspect-ratio: 21 / 17;
    }

    &.is-skeleton {
        @include skeleton-loading(0px);
    }
}

.block-card-codeorigin__image {
    flex-basis: 36.4%;
    flex-shrink: 0;
}

.block-card-codeorigin__content {
    @include fluid('padding', 10px, 20px, 20px);

    display: flex;
    flex-basis: 63.6%;
    flex-direction: column;
    justify-content: center;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
}

.block-card-codeorigin__content-title,
.block-card-codeorigin__content-subtitle {
    font-family: var(--f-family--primary);
}

.block-card-codeorigin__content-title {
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--a);

    @include tablet {
        @include fluid('--f-size--h3', 18px, 18px, 30px);
        @include fluid('--l-height--h3', 25.2px, 25.2px, 36px);

        font-size: var(--f-size--h3);
        font-weight: var(--f-weight--500);
        line-height: var(--l-height--h3);
    }
}

.block-card-codeorigin__content-subtitle {
    font-size: var(--f-size--description);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--description);

    @include tablet {
        font-size: var(--f-size--p);
        line-height: var(--l-height--p);
    }
}
</style>

<template>
  <div
    class="block-auth-user-freecodes"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div class="block-auth-user-freecodes__inner">
      <AtomTextHeading
        v-if="title"
        html-tag="h2"
        :text="title"
        class="block-auth-user-freecodes__title"
        font-size="h3"
      />

      <BlockFilterTabs
        v-if="filterTabs?.length > 0"
        :tabs="filterTabs"
        :initial-active-tab="filterTabs[0].name"
      />

      <BlockTableRows
        :rows="data"
      />

      <BlockPaginationNumbers
        v-if="pages > 0"
        class="block-auth-user-freecodes__pagination"
        :total-pages="pages"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    /*
        Content
    */
    title: {
        type: String,
        default: '',
    },

    /*
        Settings
    */
    paginationSize: {
        type: Number,
        default: 5,
    },

    pages: {
        type: Number,
        default: 0,
    },

    filterTabs: {
        type: Array,
        default: () => [],
    },

    /*
        Storyblok Dummy Data
    */
    dummyData: {
        type: Array,
        default: null,
    },
});

const { public: publicRuntimeConfig } = useRuntimeConfig();
const data = ref(publicRuntimeConfig.IS_STORYBOOK ? props.dummyData : []);
const { dayjs } = useDayJs();

if (!publicRuntimeConfig.IS_STORYBOOK) {
    const { content } = getModuleData('freeCodes');
    const { columnsSettings: columnsDefaults } = content;

    try {
        const codes = await useShowFreeCodes();

        if (!codes) {
            useSentryError(e, {
                useThrow: true,
                payload: null,
                response: codes,
            });
        }

        codes?.forEach((code) => {
            const rowInsert = JSON.parse(JSON.stringify(columnsDefaults));
            rowInsert[1].content.subtitle = compileWithHandlebars(
                rowInsert[1].content.subtitle,
                code,
            );

            const readableDate = {
                played_at: dayjs(code.played_at).format('DD.MM.YYYY'),
            };

            if (code.played_at) {
                rowInsert[2].content.subtitle = compileWithHandlebars(
                    rowInsert[2].content.playedText,
                    readableDate,
                );
                rowInsert[2].content.buttonText = null;
                rowInsert[2].content.buttonAction = null;
            } else {
                rowInsert[2].content.buttonValue = code.code;
            }

            data.value.push({
                columns: rowInsert,
            });
        });
    } catch (e) {
        useSentryError(e, {
            useThrow: false,
            payload: null,
        });
    }
}
</script>

<style lang="scss" scoped>
.block-auth-user-freecodes {
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--medium);
    }
}

.block-auth-user-freecodes__inner {
    @include box-default;

    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @include tablet {
        row-gap: 20px;
    }

    .is-on-grid & {
        @include default-content-columns('wide');
    }
}

.block-auth-user-freecodes__pagination {
    max-width: 500px;

    @include tablet-portrait {
        align-self: center;
    }
}
</style>

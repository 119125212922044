<template>
  <div class="block-filter-tabs">
    <AtomTab
      v-for="(tab, index) of tabs"
      :key="`${tab.name}-${index}`"
      :text="tab.text"
      :active-background-color="activeBackgroundColor"
      :active-text-color="activeTextColor"
      :inactive-background-color="inactiveBackgroundColor"
      :inactive-text-color="inactiveTextColor"
      :is-active="activeTabs.includes(tab.name)"
      @click="handleTabClick(tab)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    tabs: {
        type: Array,
        default: () => [],
    },

    allowMultiple: {
        type: Boolean,
        default: false,
    },

    /*
      Styles
    */
    activeBackgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    activeTextColor: {
        type: String,
        default: 'var(--c-white)',
    },

    inactiveBackgroundColor: {
        type: String,
        default: 'var(--c-grey--light)',
    },

    inactiveTextColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
        Initial
    */
    initialActiveTab: {
        type: String,
        default: '',
    },

});

const activeTabs = ref(props.initialActiveTab ? [props.initialActiveTab] : []);
const emit = defineEmits(['on-tab-click']);

const emitHandler = () => {
    const valueToEmit = props.allowMultiple ? activeTabs.value : activeTabs.value[0];
    emit('on-tab-click', valueToEmit);
    useStorybookAction('on-tab-click', valueToEmit);
};

const handleTabClick = (tab) => {
    if (props.allowMultiple && activeTabs.value.includes(tab.name)) {
        activeTabs.value = activeTabs.value.filter(
            (activeTab) => activeTab !== tab.name,
        );

        emitHandler();
        return;
    }

    if (props.allowMultiple && !activeTabs.value.includes(tab.name)) {
        activeTabs.value = [...activeTabs.value, tab.name];
        emitHandler();
        return;
    }

    activeTabs.value = [tab.name];

    sendEvent({
        event: 'click',
        value: tab.name,
        category: 'filter',
        label: tab.text,
    });

    emitHandler();
};
</script>

<style lang="scss" scoped>
.block-filter-tabs {
    @include fluid('column-gap', 10px, 10px, 15px, false);
    @include remove-scrollbars;

    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
}
</style>

<template>
  <div class="block-pagination-numbers__wrapper">
    <div v-if="isSkeleton" class="block-pagination-numbers__skeleton">
      <div class="block-pagination-numbers__skeleton-button is-skeleton" />
      <div
        v-for="i in 5"
        :key="`pagination-number-skeleton-${i}`"
        class="block-pagination-numbers__skeleton-number is-skeleton"
      />
      <div class="block-pagination-numbers__skeleton-button is-skeleton" />
    </div>

    <div
      v-else
      class="block-pagination-numbers"
    >
      <button
        class="block-pagination-numbers__button"
        type="button"
        :disabled="activePage === 1"
        @click="navigateTo(activePage - 1)"
      >
        <IonIcon
          :icon-color="iconColor"
          icon-name="arrow-left"
        />
      </button>

      <div class="block-pagination-numbers__numbers">
        <button
          type="button"
          class="block-pagination-numbers__page-number"
          :class="{ 'is-active': dynamicFirstNumber === activePage }"
          @click="navigateTo(dynamicFirstNumber)"
        >
          {{ dynamicFirstNumber }}
        </button>

        <div
          v-if="showDots && dotsPosition === 'left'"
          class="block-pagination-numbers__dots"
        >
          {{ seperator }}
        </div>

        <button
          v-for="number in pageNumbers"
          :key="number"
          type="button"
          class="block-pagination-numbers__page-number"
          :class="{ 'is-active': number === activePage }"
          @click="navigateTo(number)"
        >
          {{ number }}
        </button>

        <div
          v-if="showDots && dotsPosition === 'right'"
          class="block-pagination-numbers__dots"
        >
          {{ seperator }}
        </div>
        <button
          type="button"
          class="block-pagination-numbers__page-number"
          :class="{ 'is-active': activePage === totalPages }"
          @click="navigateTo(dynamicLastNumber)"
        >
          {{ dynamicLastNumber }}
        </button>
      </div>

      <button
        class="block-pagination-numbers__button"
        type="button"
        :disabled="activePage === totalPages"
        @click="navigateTo(activePage + 1)"
      >
        <IonIcon
          :icon-color="iconColor"
          icon-name="arrow-right"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    totalPages: {
        type: Number,
        required: true,
    },

    numberAmount: {
        type: Number,
        default: 5,
    },

    /*
      Styles
    */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    iconColor: {
        type: String,
        default: 'var(--c-white)',
    },

    seperator: {
        type: String,
        default: '...',
    },

    isSkeleton: {
        type: Boolean,
        default: false,
    },
});

const {
    borderRadius,
    backgroundColor,
    textColor,
    totalPages,
    numberAmount,
} = toRefs(props);

/*
  Active
*/
const activePage = ref(1);

/*
  Variables
*/
const showDots = computed(() => totalPages.value > numberAmount.value && totalPages.value > 5);

const lastNumber = computed(() => totalPages.value);
const firstNumber = 1;
const dotsPosition = computed(() => {
    if (showDots.value === false) return null;
    if (activePage.value >= totalPages.value / 2) return 'left';
    if (activePage.value < totalPages.value / 2) return 'right';
    return 'center';
});

/*
  Navigation
*/
const emit = defineEmits(['on-page-change']);
const navigateTo = (index) => {
    activePage.value = index;
    emit('on-page-change', index);
};

/*
  Renderer
*/
const dynamicFirstNumber = computed(() => {
    if (dotsPosition.value === 'right') {
        return Math.max(1, activePage.value - Math.floor((numberAmount.value - 3) / 2));
    }
    return firstNumber;
});

const dynamicLastNumber = computed(() => {
    if (dotsPosition.value === 'left') {
        return Math.min(totalPages.value, activePage.value + Math.floor(
            (numberAmount.value - 3) / 2,
        ));
    }
    return lastNumber.value;
});

const pageNumbers = computed(() => {
    if (totalPages.value <= numberAmount.value) {
        return Array.from({ length: totalPages.value - 2 }, (_, i) => i + 2);
    }

    let start = Math.max(dynamicFirstNumber.value + 1, activePage.value - Math.floor(
        (numberAmount.value - 3) / 2,
    ));
    let end = Math.min(dynamicLastNumber.value - 1, start + numberAmount.value - 3 - 1);

    if (start <= dynamicFirstNumber.value + 1) {
        start = dynamicFirstNumber.value + 1;
        end = Math.min(dynamicLastNumber.value - 1, start + numberAmount.value - 3 - 1);
    }

    if (end >= dynamicLastNumber.value - 1) {
        end = dynamicLastNumber.value - 1;
        start = Math.max(dynamicFirstNumber.value + 1, end - numberAmount.value + 3 + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});

const reset = () => {
    activePage.value = 1;
};

defineExpose({
    reset,
});
</script>

<style lang="scss" scoped>
.block-pagination-numbers,
.block-pagination-numbers__skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    @include tablet {
        column-gap: 30px;
    }

}

.block-pagination-numbers__button,
.block-pagination-numbers__skeleton-button {
    @include fluid('width', 50px, 50px, 59px, false);
    @include fluid('height', 50px, 50px, 59px, false);
    position: relative;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    transition: opacity 0.2s ease-in-out;

    ::v-deep(.ion-icon__svg) {
        @include fluid('width', 12px, 12px, 17px);
    }

    &:disabled {
        opacity: 0.2;
    }

    &.is-skeleton {
        @include skeleton-loading(var(--b-radius--full));
    }
}

.block-pagination-numbers__numbers {
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet-portrait {
        display: flex;
        padding-right: 20px;
        padding-left: 20px;
        column-gap: 10px;
    }
}

.block-pagination-numbers__page-number, {
    padding-right: 10px;
    padding-left: 10px;
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--a);

    &.is-active {
        text-decoration: underline;
    }
}

.block-pagination-numbers__dots {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--700);
    line-height: var(--l-height--a);
}
</style>

<template>
  <div
    :class="{
      'is-empty': !isCollected,
      'is-last': isLast,
      'is-skeleton': isSkeleton,
    }"
    class="atom-sticker"
  >
    <div class="atom-sticker__image-wrapper">
      <AtomImage
        v-if="image.src"
        class="atom-sticker__image"
        :image="isCollected ? image : emptyImage"
        behaviour="contain"
      />
    </div>

    <div
      class="atom-sticker__amount"
      :class="{
        'is-position-left': unitPosition === 'left',
      }"
    >
      <span>{{ amount }}</span>
      <span>{{ unit }}</span>
    </div>

    <div
      v-if="isLast"
      class="atom-sticker__disturber"
      :class="{
        'is-position-left': unitPosition === 'left',
      }"
    >
      <span>{{ amount }}</span>
      <span>{{ unit }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    backgroundColor: {
        type: String,
        default: 'var(--c-white)',
    },

    amount: {
        type: Number,
        default: 0,
    },

    unit: {
        type: String,
        default: '',
    },

    unitPosition: {
        type: String,
        default: 'right',
    },

    isCollected: {
        type: Boolean,
        default: false,
    },

    image: {
        type: Object,
        default: () => ({}),
    },

    emptyImage: {
        type: Object,
        default: () => ({}),
    },

    isLast: {
        type: Boolean,
        default: false,
    },

    isSkeleton: {
        type: Boolean,
        default: false,
    },

});

const {
    backgroundColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-sticker {
    --accent-color: v-bind(backgroundColor);
    position: relative;
    width: 100%;
    border: 7px solid var(--c-white);
    border-radius: var(--b-radius--full);
    aspect-ratio: 1 / 1;
    background-color: var(--accent-color);

    @include skeleton-loading;

    ::v-deep(.atom-image__background) {
        transform: scale(1.01);
    }

    &.is-empty {
        background-color: transparent;

        &.is-last {
            border-color: transparent;
        }
    }

    &:not(.is-empty) {
        &.is-last {
            border-color: var(--c-grey--light);
        }
    }

    &:before {
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
        border: 0.543px dashed var(--c-primary--light);
        border-radius: var(--b-radius--full);
        content: '';
    }
}

.atom-sticker__amount {
    @include fluid('font-size', 18px, 18px, 28px);

    position: absolute;
    top: 44%;
    right: 5%;
    display: flex;
    width: 27%;
    height: 27%;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background-color: var(--c-white);
    color: var(--accent-color);
    font-family: var(--f-family--primary);
    font-weight: var(--f-weight--900);

    .is-empty & {
        @include fluid('font-size', 14px, 14px, 16px);

        top: 50%;
        left: 50%;
        width: 16%;
        height: 16%;
        background: var(--c-primary--light);
        color: var(--c-white);
        transform: translate(-50%, -50%);
    }

    &.is-position-left {
        span:last-child {
            order: -1;
        }
    }
}

.atom-sticker-image__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: var(--b-radius--full);
}

.atom-sticker__disturber {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    display: none;
    width: 99px;
    height: 99px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background-color: var(--c-grey);
    color: var(--c-white);
    font-family: var(--f-family--primary);
    font-size: 20px;
    font-weight: var(--f-weight--900);
    transform: translateX(50%) rotate(-30deg);

    @include tablet-portrait {
        @include fluid('width', 50px, 50px, 99px);
        @include fluid('height', 50px, 50px, 99px);
    }

    .is-empty & {
        display: flex;
    }

    &.is-position-left {
        span:last-child {
            order: -1;
        }
    }
}
</style>

import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');
dayjs.locale(de);

export default () => ({ dayjs });

<template>
  <div
    class="block-slider-wins"
    :class="{
      'is-on-grid': isOnGrid,
    }"
  >
    <div class="block-slider-wins__container">
      <div class="block-slider-wins__inner">
        <ClientOnly>
          <swiper-container
            ref="swiperRef"
            class="block-slider-wins__swiper"
            :slides-per-view="1"
            :space-between="20"
            style="overflow: visible;"
            :breakpoints="{
              500: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              750: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @swiperslidechange="onSlideChange"
          >
            <swiper-slide
              v-for="(prize, index) of prizes"
              :key="`slide-${Math.random() * index}`"
              class="block-slider-wins__slide"
            >
              <BlockCardPrize
                v-bind="prize"
                :points-text="pointsText"
              />
            </swiper-slide>
          </swiper-container>
        </ClientOnly>
      </div>

      <div
        v-if="hasControls && showPagination"
        class="block-slider-wins__navigation"
      >
        <button
          class="block-slider-wins__button"
          type="button"
          :disabled="isDisabledPrev"
          @click="slideTo('prev')"
        >
          <IonIcon
            icon-color="var(--c-white)"
            icon-name="arrow-left"
          />
        </button>
        <button
          class="block-slider-wins__button"
          type="button"
          :disabled="isDisabledNext"
          @click="slideTo('next')"
        >
          <IonIcon
            icon-color="var(--c-white)"
            icon-name="arrow-right"
          />
        </button>
      </div>

      <div class="block-slider-wins__links">
        <UtilRouteLink
          v-for="(link, index) of links"
          :key="`blocks-lider-wins-link-${Math.random() * index}`"
          :link="link.link"
        >
          <AtomButton
            :background-color="link.backgroundColor"
            :border-color="link.borderColor"
            :text="link.text"
            :text-color="link.textColor"
          />
        </UtilRouteLink>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Layout */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    hasControls: {
        type: Boolean,
        default: true,
    },

    /* Content */
    prizes: {
        type: Array,
        default: () => [],
    },

    links: {
        type: Array,
        default: () => [],
    },

    pointsText: {
        type: String,
        default: '',
    },

    /*
        Styles
    */
    accentColor: {
        type: String,
        default: 'var(--c-primary)',
    },
});

const { accentColor } = toRefs(props);

/*
    Init Swiperjs
*/
await useSwiperJs();
const swiperRef = ref(null);

const slideTo = (direction) => {
    if (!swiperRef.value) return;

    if (direction === 'next') {
        swiperRef.value.swiper.slideNext();
        return;
    }

    if (direction === 'prev') {
        swiperRef.value.swiper.slidePrev();
    }
};

const isDisabledPrev = ref(true);
const isDisabledNext = ref(false);

const onSlideChange = (event) => {
    const [swiper] = event.detail;
    const { isEnd, isBeginning } = swiper;

    isDisabledPrev.value = isBeginning === true;
    isDisabledNext.value = isEnd === true;
};

/* Show pagination */
const showPagination = computed(() => {
    const itemsPerView = isGreaterThanTabletPortrait?.value ? 3 : 2;
    return props.prizes.length > itemsPerView;
});
</script>

<style lang="scss" scoped>
.block-slider-wins {
    overflow: hidden;

}

.block-slider-wins__container {
    display: flex;
    overflow: visible;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;

    .is-on-grid &{
        @include wrapper-layout();
        margin-bottom: var(--m-bottom--medium);
    }

    ::part(container) {
        overflow: visible;
    }
}

.block-slider-wins__inner {
    overflow: visible;
}

.block-slider-wins__navigation,
.block-slider-wins__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    row-gap: 10px;
}

.block-slider-wins__button {
    @include navigation-buttons;
    background-color: v-bind(accentColor);

    ::v-deep(.ion-icon__svg) {
        @include fluid('width', 12px, 12px, 17px);
    }
}
</style>
